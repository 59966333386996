import React, { useEffect, useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useStore } from '../../store';
import { AutoTextSize } from 'auto-text-size';

interface state {
    id?: number;
    size?: string;
    x?: number;
    y?: number;
    rotation?: number;
    scale?: number;
    url?: string;
    deleteRef?: any;
    z: number;
}

const StickerM: React.FC<state> = (props: state) => {

    const [state, setState] = useState<state>({...props})
    const { updateSticker, name, deleteSticker} = useStore();
    const [isCollisioning, setIsCollisioning] = useState(false);
    const collisionRef = useRef<HTMLImageElement>(null); 
    const imgRef = useRef<HTMLDivElement>(null);

    const x = useMotionValue(state.x);
    const y = useMotionValue(state.y);
    const scale = useMotionValue(state.scale);
    const rotate = useMotionValue(state.rotation);

    useEffect(() =>{

        setState({ ...props })

    }, [])

    type Rect = {
        left: number;
        top: number;
        right: number;
        bottom: number;
    };

    const getBoundingClientRect = (element: HTMLElement): Rect => {
        const rect = element.getBoundingClientRect();
        return {
          left: rect.left,
          top: rect.top,
          right: rect.right,
          bottom: rect.bottom
        };
    };

    const isColliding = (rect1: Rect, rect2: Rect): boolean => {
      return !(
        rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom
      );
    };

    const checkCollision = () => {
      
      if(props.id === 99) return
      if (collisionRef.current && props.deleteRef.current) {
        const element1 = collisionRef.current as HTMLElement;
        const rect1 = getBoundingClientRect(element1);
        const rect2 = getBoundingClientRect(props.deleteRef.current);
        if(isColliding(rect1, rect2)){
          props.deleteRef.current.className = 'absolute w-16 h-12 left-1/2 -translate-x-1/2 bottom-2 transition-all hue-rotate-90'
          setIsCollisioning(true)
        }else{
          props.deleteRef.current.className = 'absolute w-12 h-18 left-1/2 -translate-x-1/2 bottom-2 transition-all hue-rotate-0'
          setIsCollisioning(false)
        }
        
      }
    };

    const deleteElement = () => {

      props.deleteRef.current.className = 'absolute w-12 h-18 left-1/2 -translate-x-1/2 bottom-2 transition-all hue-rotate-0'
      if(isCollisioning)
        deleteSticker(props)
      
    }

    useGesture(
        {
            onDrag: ({ offset: [dx, dy] }) => {
                x.set(dx);
                y.set(dy);
                //updateSticker({...state})
                checkCollision()
            },
            onPinch: ({ offset: [s, r], memo }) => {
                scale.set(s)
                rotate.set(r)
                return memo
            },
            onDragEnd:()=>{
                updateSticker({...state, scale: scale.get(), rotation: rotate.get(), x: x.get(), y: y.get()})
                deleteElement()
            }
        },
        {
            target: imgRef,
            drag: { from: () => [x.get(), y.get()] },
            pinch: { scaleBounds: { min: 0.5, max: 10 } },
            eventOptions: { passive: false },
        }
    );

    return (<>
        <div>
            <motion.div
                ref={imgRef}
                style={{
                    touchAction: 'none',
                    position: 'absolute',
                    width: state.size+'px',
                    height: state.size+'px',
                    backgroundImage: `url('${props.url}')`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    x,
                    y,
                    scale,
                    rotate: useTransform(rotate, r => `${r}deg`),
                    zIndex: props.z
                }}
            >
                {<div ref={collisionRef} className="w-1/4 h-1/4 absolute bottom-1/2 left-1/2 -translate-x-1/2 translate-y-1/2"/>}
                {props.id === 99 && <div className={`absolute -translate-y-1/2 left-1/2 -translate-x-[51%] w-[90%] top-1/2`}>
                    <AutoTextSize
                        className={`font-loverine text-white text-center mx-auto font-extrabold`}
                        minFontSizePx={12}
                    >
                        {`<${name}>`}
                    </AutoTextSize>
                </div>}
            </motion.div>
        </div>

    </>
    );
};

export default StickerM;