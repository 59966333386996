import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/home";
import Header from "./Components/header";
import Step1 from "./Components/step-1";
import Step2 from "./Components/step-2";
import Step3 from "./Components/step-3";
import Step4 from "./Components/step-4";
import Step7 from "./Components/step-7";
import Animation from "./Components/animation";
import Step8 from "./Components/step-8";
import { Suspense} from "react";
import SelectionScreen from "./Components/SelectionScreen";
import Enigma from "./Components/Enigma";



export default function AppRouter(){


    return (

        <>
        <BrowserRouter>
            <Suspense>
                <Routes>
                    <Route path='/' element={<SelectionScreen />} />
                    <Route element={<Header />}>
                        <Route path='celebracion' element={<Home />} />
                        <Route path='celebracion-1' element={<Step1 />} />
                        <Route path='celebracion-2' element={<Step2 />} />
                        <Route path='celebracion-animation' element={<Animation />} />
                        <Route path='celebracion-3' element={<Step3 />} />
                        <Route path='celebracion-4' element={<Step4 />} />
                        <Route path='celebracion-7' element={<Step7 />} />
                        <Route path='celebracion-8' element={<Step8 />} />
                    </Route>
                    <Route path='/enigma' element={<Enigma />} />
                </Routes>
            </Suspense>
        </BrowserRouter>


        </>
    )
}