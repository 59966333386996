import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store";
import Loading from "./helpers/Loading";

export default function Step1(){

    const { user, setUser } = useStore();
    const mainCont = useRef<HTMLDivElement>(document.createElement("div"))
    const [firstClick, setFirstClick] = useState(false)
    const navigate = useNavigate()
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [checkedPN, setCheckedPN] = useState(false);
    const select1 = useRef<HTMLSelectElement>(null)

    function handleSubmit(){
        setFirstClick(true)
        if(user.name && user.email && user.phone && user.day!==-1 && user.month!==-1 && user.year!==-1 && checkedPN){
            navigate('/celebracion-2')
        }
    }

    return (
        <>

        {!isPageLoaded && <Loading/>}

            <video preload="metadata" autoPlay muted loop className="myVideo pointer-events-none"  style={{minHeight: mainCont.current.scrollHeight || '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back2R.mp4' type="video/mp4" />
            </video>
            <div className="flex flex-row min-h-svh items-center z-50 pt-7 lg:pt-0 pb-20"  ref={mainCont}>
                <form className="w-full xl:w-1/2 p-10 space-y-6 mx-auto"
                    onSubmit={e => e.preventDefault()}
                >

                    <div className="space-y-4 relative">
                        <motion.label htmlFor="name" className="block text-[#fff] uppercase font-bloomer text-2xl tracking-wider drop-shadow text-center"
                            initial={{ opacity: 1, scale: 0, scaleX: 0, scaleY: 0 }}
                            animate={{ scale: [1, 1.2, 1], scaleX: 1, scaleY: 1}}
                            transition={{
                                scaleX:{duration: 0.3, type: "spring"},
                                scaleY:{duration: 0.3, type: "spring"},
                                scale:{
                                    delay: 0.3,
                                    type: "linear",
                                    duration: 3,
                                    repeat: Infinity
                                }
                            }}
                        >
                             Cuéntanos un poco de ti
                        </motion.label>
                        <motion.input 
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Tu nombre"
                            className={`p-5 w-full rounded-full ${!user.name && firstClick ? 'outline-dashed outline-2 -outline-offset-4 outline-red-700': 'outline-none'} text-md md:text-xl text-[#0353dc] placeholder-[#0353dc80]`}
                            initial={{ width: "0%", opacity: 0 }}
                            animate={{ width: "100%", opacity: 1 }}
                            transition={{ duration: 1.5, origin: 0, type: "spring", delay: 0.3 }}
                            value={user.name}
                            onInput={(e:any) => setUser({...user, name: e.target['value']})}
                        />
                    </div>

                    <div className="space-y-4">
                        <motion.label className="block text-[#fff] uppercase font-bloomer text-2xl tracking-wider drop-shadow text-center"
                            initial={{ opacity: 1, scale: 0, scaleX: 0, scaleY: 0 }}
                            animate={{ scale: [1.2, 1, 1.2], scaleX: 1, scaleY: 1}}
                            transition={{
                                
                                scaleX:{duration: 0.3, type: "spring"},
                                scaleY:{duration: 0.3, type: "spring"},
                                scale:{
                                    delay: 0.3,
                                    type: "linear",
                                    duration: 3,
                                    repeat: Infinity
                                }
                            }}
                        >
                            ¿Cuándo es tu cumple?
                        </motion.label>
                        <div className="flex justify-between space-x-1 md:space-x-4 lg:space-x-8">
                            <div className="basis-1/2 md:basis-1/3 relative">
                                <motion.label htmlFor="day" className="absolute right-[0%] -top-[1px] z-50 pointer-events-none"
                                    initial={{ right: "65%", scale: 0 }}
                                    animate={{ right: "0%", scale: 1 }}
                                    transition={{right:{delay: 0.5, type: "spring" }, scale:{duration: 0.2, type: "spring", delay: 0.1 }}}
                                >
                                    <img className="size-[3.1em] md:size-[3.8em] lg:size-[4em]" src="./assets/plus.png" alt="day"/>
                                </motion.label>
                                <motion.select 
                                    id="day"
                                    name="day"
                                    className={`cursor-pointer p-4 w-full rounded-full ${user.day===-1 && firstClick ? 'outline-dashed outline-2 -outline-offset-4 outline-red-700': 'outline-none'} text-[#0353dc] font-extrabold font-bloomer text-xs md:text-lg lg:text-2xl tracking-wider`}
                                    initial={{ width: "0%", scale: 0 }}
                                    animate={{ width: "100%", scale: 1 }}
                                    transition={{ width:{delay: 0.5, type: "spring" }, scale:{duration: 0.2, delay: 0.5, type: "spring" } }}
                                    value={user.day}
                                    onChange={(e:any) => setUser({...user, day: e.target['value']})}
                                    ref={select1}
                                >
                                    <option value="-1">DÍA</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                </motion.select>
                            </div>
                            <div className="basis-1/2 md:basis-1/3 relative">
                                <motion.label htmlFor="month" className="absolute right-[0%] -top-[1px] z-50 pointer-events-none"
                                    initial={{ right: "65%", scale: 0 }}
                                    animate={{ right: "0%", scale: 1 }}
                                    transition={{right:{delay: 0.6, type: "spring" }, scale:{duration: 0.3, type: "spring", delay: 0.2 }}}
                                >
                                    <img className="size-[3.1em] md:size-[3.8em] lg:size-[4em]" src="./assets/plus.png" alt="month"/>
                                </motion.label>
                                <motion.select 
                                    id="month"
                                    name="month"
                                    className={`cursor-pointer p-4 w-full rounded-full ${user.month===-1 && firstClick ? 'outline-dashed outline-2 -outline-offset-4 outline-red-700': 'outline-none'} text-[#0353dc] font-extrabold font-bloomer text-xs md:text-lg lg:text-2xl tracking-wider`}
                                    initial={{ width: "0%", scale: 0 }}
                                    animate={{ width: "100%", scale: 1 }}
                                    transition={{ width:{delay: 0.6, type: "spring" }, scale:{duration: 0.3, delay: 0.6, type: "spring" } }}
                                    value={user.month}
                                    onChange={(e:any) => setUser({...user, month: e.target['value']})}
                                >
                                    <option value="">MES</option>
                                    <option value="Enero">Enero</option>
                                    <option value="Febrero">Febrero</option>
                                    <option value="Marzo">Marzo</option>
                                    <option value="Abril">Abril</option>
                                    <option value="Mayo">Mayo</option>
                                    <option value="Junio">Junio</option>
                                    <option value="Julio">Julio</option>
                                    <option value="Agosto">Agosto</option>
                                    <option value="Septiembre">Septiembre</option>
                                    <option value="Octubre">Octubre</option>
                                    <option value="Noviembre">Noviembre</option>
                                    <option value="Diciembre">Diciembre</option>
                                </motion.select>
                            </div>
                            <div className="basis-1/2 md:basis-1/3 relative">
                                <motion.label htmlFor="year" className="absolute right-[0%] -top-[1px] z-50 pointer-events-none"
                                    initial={{ right: "65%", scale: 0 }}
                                    animate={{ right: "0%", scale: 1 }}
                                    transition={{right:{delay: 0.7, type: "spring" }, scale:{duration: 0.3, type: "spring", delay: 0.3 }}}
                                >
                                    <img className="size-[3.1em] md:size-[3.8em] lg:size-[4em]" src="./assets/plus.png" alt="year"/>
                                </motion.label>
                                <motion.select 
                                    id="year"
                                    name="year"
                                    className={`cursor-pointer p-4 w-full rounded-full ${user.year===-1 && firstClick ? 'outline-dashed outline-2 -outline-offset-4 outline-red-700': 'outline-none'} text-[#0353dc] font-extrabold font-bloomer text-xs md:text-lg lg:text-2xl tracking-wider`}
                                    initial={{ width: "0%", scale: 0 }}
                                    animate={{ width: "100%", scale: 1 }}
                                    transition={{ width:{delay: 0.7, type: "spring" }, scale:{duration: 0.3, delay: 0.7, type: "spring" } }}
                                    value={user.year}
                                    onChange={(e:any) => setUser({...user, year: e.target['value']})}
                                >
                                    <option value="">AÑO</option>
                                    <option value="2006">2006</option>
                                    <option value="2005">2005</option>
                                    <option value="2004">2004</option>
                                    <option value="2003">2003</option>
                                    <option value="2002">2002</option>
                                    <option value="2001">2001</option>
                                    <option value="2000">2000</option>
                                    <option value="1999">1999</option>
                                    <option value="1998">1998</option>
                                    <option value="1997">1997</option>
                                    <option value="1996">1996</option>
                                    <option value="1995">1995</option>
                                    <option value="1994">1994</option>
                                    <option value="1993">1993</option>
                                    <option value="1992">1992</option>
                                    <option value="1991">1991</option>
                                    <option value="1990">1990</option>
                                    <option value="1989">1989</option>
                                    <option value="1988">1988</option>
                                    <option value="1987">1987</option>
                                    <option value="1986">1986</option>
                                    <option value="1985">1985</option>
                                    <option value="1984">1984</option>
                                    <option value="1983">1983</option>
                                    <option value="1982">1982</option>
                                    <option value="1981">1981</option>
                                    <option value="1980">1980</option>
                                    <option value="1979">1979</option>
                                    <option value="1978">1978</option>
                                    <option value="1977">1977</option>
                                    <option value="1976">1976</option>
                                    <option value="1975">1975</option>
                                    <option value="1974">1974</option>
                                    <option value="1973">1973</option>
                                    <option value="1972">1972</option>
                                    <option value="1971">1971</option>
                                    <option value="1970">1970</option>
                                    <option value="1969">1969</option>
                                    <option value="1968">1968</option>
                                    <option value="1967">1967</option>
                                    <option value="1966">1966</option>
                                    <option value="1965">1965</option>
                                    <option value="1964">1964</option>
                                    <option value="1963">1963</option>
                                    <option value="1962">1962</option>
                                    <option value="1961">1961</option>
                                    <option value="1960">1960</option>
                                    <option value="1959">1959</option>
                                    <option value="1958">1958</option>
                                    <option value="1957">1957</option>
                                    <option value="1956">1956</option>
                                    <option value="1955">1955</option>
                                    <option value="1954">1954</option>
                                    <option value="1953">1953</option>
                                    <option value="1952">1952</option>
                                    <option value="1951">1951</option>
                                    <option value="1950">1950</option>
                                    <option value="1949">1949</option>
                                    <option value="1948">1948</option>
                                    <option value="1947">1947</option>
                                    <option value="1946">1946</option>
                                    <option value="1945">1945</option>
                                    <option value="1944">1944</option>
                                    <option value="1943">1943</option>
                                    <option value="1942">1942</option>
                                    <option value="1941">1941</option>
                                    <option value="1940">1940</option>
                                    <option value="1939">1939</option>
                                    <option value="1938">1938</option>
                                    <option value="1937">1937</option>
                                    <option value="1936">1936</option>
                                    <option value="1935">1935</option>
                                    <option value="1934">1934</option>
                                    <option value="1933">1933</option>
                                    <option value="1932">1932</option>
                                    <option value="1931">1931</option>
                                    <option value="1930">1930</option>
                                </motion.select>
                            </div>
                        </div>
                    </div>

                    <div className="relative" >
                        <motion.label 
                            htmlFor="email"
                            className="absolute -right-[0%] -top-[1px] z-50 cursor-pointer"
                            initial={{ right: "93%", scale: 0 }}
                            animate={{ right: "0%", scale: 1 }}
                            transition={{right:{delay: 0.8, duration: 2, type: "spring" }, scale:{duration: 0.3, type: "spring", delay: 0.4 }}}
                        >
                            <img className="size-[3.6em] md:size-[3.8em]" src="./assets/arrow.png" alt="email"/>
                        </motion.label>
                        <motion.input 
                            id="email"
                            type="text"
                            name="email"
                            className={`pr-12 p-4 w-full rounded-full ${!user.email && firstClick ? 'outline-dashed outline-2 -outline-offset-4 outline-red-700': 'outline-none'} text-left placeholder-[#0353dc80] text-md md:text-xl text-[#0353dc]`}
                            placeholder="Aquí va tu mail "
                            initial={{ width: "0%", scale: 0 }}
                            animate={{ width: "100%", scale: 1 }}
                            transition={{ width:{delay: 0.8, duration: 2, type: "spring" }, scale:{duration: 0.4, delay: 0.6, type: "spring" } }}
                            value={user.email}
                            onInput={(e:any) => setUser({...user, email: e.target['value']})}
                        />
                    </div>

                    <div className="relative" >
                        <motion.label 
                            htmlFor="phone"
                            className="absolute -right-[0%] -top-[1px] z-50 cursor-pointer"
                            initial={{ right: "93%", scale: 0 }}
                            animate={{ right: "0%", scale: 1 }}
                            transition={{right:{delay: 1, duration: 2, type: "spring" }, scale:{duration: 0.3, type: "spring", delay: 0.5 }}}
                        >
                            <img className="size-[3.6em] md:size-[3.8em]" src="./assets/arrow.png" alt="phone"/>
                        </motion.label>
                        <motion.input 
                            id="phone"
                            type="text"
                            name="phone"
                            className={`pr-12 p-4 w-full rounded-full ${!user.phone && firstClick ? 'outline-dashed outline-2 -outline-offset-4 outline-red-700': 'outline-none'} text-left placeholder-[#0353dc80] text-md md:text-xl text-[#0353dc]`}
                            placeholder="Acá tu teléfono"
                            initial={{ width: "0%", scale: 0 }}
                            animate={{ width: "100%", scale: 1 }}
                            transition={{ width:{delay: 1, duration: 2, type: "spring" }, scale:{duration: 0.4, delay: 0.6, type: "spring" } }}
                            value={user.phone}
                            onInput={(e:any) => setUser({...user, phone: e.target['value']})}
                        />
                    </div>

                    <div className="relative" >

                        <motion.ul className={`justify-center w-full md:w-1/2 mx-auto`}
                            initial={{opacity: 0}}
                            animate={{ opacity: 1 }}
                            transition={{ delay:2, duration: 2 }}
                        >
                            <li>
                                <input type="radio" id="hosting-big" name="hosting" value="hosting-big" className="hidden peer" onChange={() => setCheckedPN(true)} checked={checkedPN} />
                                <label htmlFor="hosting-big" className={`inline-flex items-center justify-center w-full p-4 text-gray-50 rounded-xl peer-checked:border-blue-600 peer-checked:text-blue-60 ${!checkedPN && firstClick ? 'outline-dashed outline-2 -outline-offset-1 outline-red-700 bg-[#ff00006e]': 'outline-none'}`}>
                                    <div className="block">
                                        <div className="w-full text-xs md:text-md lg:text-lg font-semibold">He leído y estoy de acuerdo con el</div>
                                        <div className="w-full text-xs md:text-md lg:text-lg font-extrabold"><a href="https://privacy.grupobimbo.com/mexico/policy/sitiosweb/index.html" target="_blank" rel="noreferrer" className="underline">Aviso de privacidad</a></div>
                                    </div>
                                    <svg className={`ml-8 w-10 h-10 ms-3 rtl:rotate-180 hover:text-gray-300 hover:scale-110 transition-all cursor-pointer`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeWidth="1.5" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"/>
                                        {checkedPN && <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>}
                                    </svg>
                                </label>
                            </li>
                        </motion.ul>
                        
                    </div>

                    <div className="text-center mx-auto w-full z-50 pt-0 md:pt-20 mt-0">
                            <motion.button 
                                type="submit"
                                className="cursor-pointer bg-[#ebff00] hover:bg-[#eaff00e5] text-[#0353da] text-md md:text-xl lg:text-3xl font-bloomer py-3 md:py-4 px-10 md:px-16 rounded-full uppercase"
                                animate={{ scale: [1, 1.2, 1]}}
                                transition={{
                                  type: "linear",
                                  duration: 3,
                                  repeat: Infinity
                                }}
                                onClick={()=>handleSubmit()}
                            >
                                CONTINUAR
                          </motion.button>
                    </div> 

                </form> 

            </div>
            
        </>
    )
}