import { Link, Outlet } from "react-router-dom";

export default function Header(){
    return (
        <>
        
            <header className="-mb-[3.15rem] md:-mb-[3.6rem] lg:-mb-[4.6rem] xl:-mb-[5.55rem] top-0 left-0 right-0 z-[100] px-2 absolute">
                <div className="mx-auto container pt-1">
                    <div className="flex justify-between items-center mx-3 mt-2">
                        <div>
                            <Link to="/"><img className="w-10 lg:w-14 hover:scale-110 transition-all cursor-pointer" src="/assets/home2.png" alt="logotipo"/></Link>
                        </div>
                        <div>
                            <Link to="/celebracion"><img className="w-20 md:w-24 lg:w-32 xl:w-40" src="/assets/marinela-logo.png" alt="logotipo"/></Link>
                        </div>
                        <div>
                            <Link to="/celebracion"><img className="w-16 md:w-20 lg:w-24 xl:w-32" src="/assets/pinguinos.png" alt="logotipo"/></Link>
                        </div>
                        {/*<div>
                            <img className="w-10 md:w-12 lg:w-16" src="/assets/menu.png" alt="logotipo"/>
                        </div>*/}
                    </div> 
                </div>
            </header>
            
            <main className="mx-auto area overflow-hidden">
                <Outlet />
            </main>

            <footer className="w-full z-50 px-2 -mt-16">

                 <div className="flex justify-center items-center mx-3 text-center">
                    <div>
                        <p
                            className="text-[#ffffffad] transition-all text-center text-xs md:text-sm"
                        >® MARCAS REGISTRADAS; D.R. © 2024 UTILIZADOS
                        BAJO LICENCIA DE SU TITULAR GRUPO BIMBO, S.A.B DE
                        C.V</p>
                    </div>
                </div>
                <div className="flex justify-between items-center mx-3 ">
                        <div>
                        <a href="https://www.bimbo.com.mx/es/terminos-y-condiciones" target="_blank" rel="noreferrer"
                            className="text-[#ffffffad] hover:text-[#ffffff] transition-all underline text-xs md:text-sm"
                        >Términos y condiciones</a>
                        </div>
                        <div>
                            <p
                                className="text-[#ffffffad] transition-all text-center text-xs md:text-sm"
                            >"Come bien"</p>
                        </div>
                        <div>
                            <a href="https://privacy.grupobimbo.com/mexico/policy/sitiosweb/index.html" target="_blank" rel="noreferrer"
                                className="text-[#ffffffad] hover:text-[#ffffff] transition-all underline text-xs md:text-sm"
                            >Aviso de privacidad</a>
                        </div>
                </div> 
        
            </footer>  
        </>
    )
}