import { delay, motion } from "framer-motion";

type AnimatedTextProps = {
    text: string | string[];
    el?: keyof JSX.IntrinsicElements;
    className?: string;
    stagger?: number;
    delay?: number;
  };
  
  const listItem = {
    hidden: { opacity: 0, transform: "translateY(5px)" },
    show: { opacity: 1, transform: "translateY(0px)" }
  };
  
  export const AnimatedText = ({
    text,
    el: Wrapper = "p",
    className,
    stagger,
    delay
  }: AnimatedTextProps) => {
    const textArray = Array.isArray(text) ? text : [text];
  
    return (
      <p className={className}>
        <span className="sr-only">{textArray.join(" ")}</span>
        <motion.span
          className="inline-block"
          variants={{
            hidden: { opacity: 0, transform: "translateY(5px)" },
            show: {
                opacity: 1,
                transform: "translateY(0px)",
                transition: {
                    staggerChildren: stagger,
                    delayChildren: delay,
                }
            }
          }} 
          initial="hidden" animate="show"
          aria-hidden
        >
          {textArray.map((line, lineIndex) => (
            <motion.span className="block" 
                key={`${line}-${lineIndex}`}
                variants={listItem}
            >
                {line}
            </motion.span>
          ))}
        </motion.span>
      </p>
    );
  };