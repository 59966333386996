import { Link, useNavigate } from "react-router-dom";
import WavyText from "./helpers/WavyText";
import { AnimatedText } from "./helpers/AnimatedText";
import { motion } from "framer-motion";
import { useStore } from "../store";
import { useEffect, useRef, useState } from "react";
import Loading from "./helpers/Loading";
import PhoneMockup from "./helpers/PhoneMockup";
import PhoneMockup2 from "./helpers/PhoneMockup2";

export default function Step7(){

    const navigate = useNavigate();
    const { name, image } = useStore();
    const mainCont = useRef<HTMLDivElement>(document.createElement("div"))
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [isMobile] = useState<boolean>(window.innerWidth < 1024);
    const [scale, setScale] = useState((window.innerHeight * 0.7) / 600);
    const [mt, setmt] = useState(0);

    useEffect(()=>{
        window.scrollTo(0, 0)
        if(!name){
            navigate("/celebracion");
        }
        if(window.innerHeight > 300){
            setmt(-300)
        }
        if(window.innerHeight > 500){
            setmt(-250)
        }
        if(window.innerHeight > 600){
            setmt(-150)
        }
        if(window.innerHeight > 700){
            setmt(-50)
        }
        if(window.innerHeight > 900){
            setmt(50)
        }
        if(window.innerHeight > 1300){
            setmt(150)
        }
    },[])

    function Share(socialmedia: string){
        switch(socialmedia){
            case 'face':
                window.open(
                    'https://www.facebook.com/',
                    '_blank'
                ); 
                break
            case 'ig':
              window.open(
                  'https://www.instagram.com/',
                  '_blank'
            ); break
            case 'tik':
              window.open(
                  'https://www.tiktok.com/',
                  '_blank'
            ); break
            case 'x':
              window.open(
                  'https://www.x.com/',
                  '_blank'
            ); break
            case 'whats':
                const mensaje = "Mira mi imagen";
                const urlWhatsApp = `https://wa.me/?text=${encodeURIComponent(mensaje)}`;//&image=${encodeURIComponent(imageUrl)}
              window.open(
                    urlWhatsApp,
                  '_blank'
            ); break
        }
    }

    const downloadImage = (fileName:string) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.download = fileName;
        
        fakeLink.href = image;

        fakeLink.target = "_blank"
        
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        
        fakeLink.remove();
    };

    const shareOnFacebook = (imageUrl: string) => {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(imageUrl)}`;
        console.log(facebookShareUrl);
        
        //window.open(facebookShareUrl, '_blank');
    };

    return (
        <>

        {!isPageLoaded && <Loading/>}
            <video preload="metadata" autoPlay muted loop className="myVideo pointer-events-none" style={{minHeight: mainCont.current.scrollHeight || '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back8v2R.mp4' type="video/mp4" />
            </video>
            
            <div className="flex flex-row min-h-svh items-center mx-10 md:mx-20 justify-center relative pt-5 lg:pt-0 pb-20" ref={mainCont}>
                {/* {!isMobile&&<motion.img className="absolute bottom-0 left-10 z-20" src="./assets/woman.png " 
                            animate={{scale: [1, 1.05, 1], bottom: [0, '-0%', 0]}}
                            transition={{
                                type: "linear",
                                duration: 8,
                                repeat: Infinity,
                                repeatDelay: 1
                            }}
                />} */}
                <div className="basis-0 min-h-svh lg:basis-2/5 relative hidden lg:block">
                   <motion.div
                        className="absolute !top-[50%] !-translate-y-1/2 left-[50%] -translate-x-1/2 z-50"
                   >
                    {/* <PhoneMockup generatedImageSrc={image} scale={scale} mt={mt} mb={0}/> */}
                    <PhoneMockup2 generatedImageSrc={image} width="100%"/>
                   </motion.div>

                </div>
                <div className="basis-full lg:basis-1/3 mt-6">

                     {isMobile && <div className="w-1/2 mx-auto"><PhoneMockup2 generatedImageSrc={image} width="66%"/></div>} {/*<PhoneMockup generatedImageSrc={image} scale={0.6}  mt={-100} mb={-90}/> */}

                    <div className="w-full md:w-1/2 mx-auto z-50 mt-2">
                        <WavyText x={-50} y={100} z={120} className="text-[#ebff00] font-ttBold text-4xl md:text-5xl lg:text-5xl xl:text-7xl inline-block text-center uppercase scale-y-125 drop-shadow justify-center" text="¡DESCARGA" replay={true} />
                        <WavyText x={50} y={-100} z={-180} className="text-[#ebff00] font-ttBold text-4xl md:text-5xl lg:text-5xl xl:text-7xl inline-block text-center uppercase scale-y-125 drop-shadow leading-[3.5rem] md:leading-[5rem] lg:leading-[5rem] xl:leading-[7rem] justify-center" text="Y COMPARTE!" replay={true} />
                    </div>
                    <div className="flex flex-row justify-center space-x-2 md:space-x-6 mt-0 md:mt-2 mb-6 z-50">
                        <motion.img className="size-10 lg:size-12 xl:size-16 p-1 cursor-pointer z-50 mt-1 md:mt-0" src="./assets/descarga.png" alt="" 
                            animate={{ scale: [0, 1.1, 1]}}
                            transition={{
                              type: "linear",
                              duration: 0.4
                            }}
                            onClick={() => downloadImage("TarjetaPersonalizada")}
                        />
                        {/*<motion.img className="size-10 lg:size-12 xl:size-16 p-1 cursor-pointer z-50 mt-1 md:mt-0" src="./assets/titok.png" alt="" 
                            animate={{ scale: [0, 1.1, 1]}}
                            transition={{
                                type: "linear",
                                duration: 0.4,
                                delay:0.3
                            }}
                            onClick={() => Share('tik')}
                        />
                        <motion.img className="size-10 lg:size-12 xl:size-16 p-1 cursor-pointer z-50 mt-1 md:mt-0" src="./assets/insta.png" alt="" 
                            animate={{ scale: [0, 1.1, 1]}}
                            transition={{
                                type: "linear",
                                duration: 0.4,
                                delay:0.6
                            }}
                            onClick={() => Share('ig')}
                        />
                        <motion.img className="size-10 lg:size-12 xl:size-16 p-1 cursor-pointer z-50 mt-1 md:mt-0" src="./assets/twitter.png" alt="" 
                            animate={{ scale: [0, 1.1, 1]}}
                            transition={{
                                type: "linear",
                                duration: 0.4,
                                delay:0.9
                            }}
                            onClick={() => Share('x')}
                        />
                        <motion.img className="size-10 lg:size-12 xl:size-16 p-1 cursor-pointer z-50 mt-1 md:mt-0" src="./assets/fb.png" alt="" 
                            animate={{ scale: [0, 1.1, 1]}}
                            transition={{
                                type: "linear",
                                duration: 0.4,
                                delay:1.2
                            }}
                            onClick={() => Share('face')}
                        />
                        <motion.img className="size-10 lg:size-12 xl:size-16 p-1 cursor-pointer z-50 mt-1 md:mt-0" src="./assets/whats.png" alt="" 
                            animate={{ scale: [0, 1.1, 1]}}
                            transition={{
                                type: "linear",
                                duration: 0.4,
                                delay:1.5
                            }}
                            onClick={() => Share('whats')}
                        />*/}
                    </div>

                    <div className="flex flex-row mt-4 mb-10 justify-center space-x-12">
                        <Link to={`${isMobile? '/celebracion-2': '/celebracion-2'}`}>
                            <motion.button 
                                type="submit"
                                className="cursor-pointer bg-[#ebff00] hover:bg-[#eaff00e5] text-[#0353da] text-sm md:text-lg lg:text-xl font-bloomer py-2 px-8 rounded-full uppercase"
                                animate={{ scale: [1, 1.2, 1]}}
                                transition={{
                                  type: "linear",
                                  duration: 3,
                                  repeat: Infinity
                                }}
                            >
                                Personaliza otro pastelito
                            </motion.button>
                        </Link>
                    </div> 

                    {/* <div className="flex flex-row mt-4 w-full 2xl:w-1/2 mx-auto">
                        <AnimatedText
                                el="p"
                                text={[
                                    "¡Tu sorpresa está lista! Comparte y etiqueta a quien celebras.",
                                    "No olvides etiquetar a Pingüinos para que juntos celebremos solo porque nos gusta."
                                ]}
                                stagger={0.3}
                                delay={1.4}
                                className="text-white text-center font-light text-xs md:text-sm lg:text-xl mx-auto"
                        />
                    </div>  */}

                    <div className="mt-10 lg:mt-20 w-full md:w-1/2 mx-auto">
                        <WavyText x={-50} y={100} z={120} className="text-[#fff] font-ttBold text-4xl md:text-5xl lg:text-5xl xl:text-7xl inline-block text-center uppercase scale-y-125 drop-shadow justify-center" text="¿Quieres más?" replay={true} delay={1.5}/>
                        {/* <WavyText x={50} y={-100} z={-180} className="text-[#fff] font-ttBold text-4xl md:text-5xl lg:text-5xl xl:text-7xl inline-block text-center uppercase scale-y-125 drop-shadow leading-[3.5rem] md:leading-[5rem] lg:leading-[5rem] xl:leading-[7rem] justify-center" text="TU pingüino" replay={true} delay={1.5}/> */}
                    </div>

                    <div className="flex flex-row mt-3 w-full 2xl:w-1/2 mx-auto">
                        <AnimatedText
                                el="p"
                                text={[
                                    "Descarga más sorpresas"
                                ]}
                                stagger={0.3}
                                delay={2}
                                className="text-white text-center font-light text-xs md:text-sm lg:text-xl mx-auto"
                        />
                    </div>

                    <div className="flex flex-row mt-8 mb-10 justify-center space-x-12">
                        <Link to={`${isMobile? '/celebracion-8': '/celebracion-8'}`}>
                            <motion.button 
                                type="submit"
                                className="cursor-pointer bg-[#ebff00] hover:bg-[#eaff00e5] text-[#0353da] text-sm md:text-xl lg:text-2xl font-bloomer py-2 px-8 rounded-full uppercase"
                                animate={{ scale: [1.2, 1, 1.2]}}
                                transition={{
                                  type: "linear",
                                  duration: 3,
                                  repeat: Infinity
                                }}
                            >
                                Siguiente
                            </motion.button>
                        </Link>
                    </div> 

                </div>
            </div>
              
            
        </>
    )
}