import { useRef } from 'react'
import { useSprings, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { clamp } from "lodash";
import { useStore } from '../../store';

const pages = [
  './assets/backs/Fondo1.png',
  './assets/backs/Fondo2.png',
  './assets/backs/Fondo3.png',
  './assets/backs/Fondo4.png',
  './assets/backs/Fondo5.png',
  './assets/backs/Fondo6.png',
  './assets/backs/Fondo7.png',
  './assets/backs/Fondo8.png',
  './assets/backs/Fondo9.png',
]

function BackPager() {

    const {setBackground } = useStore();
    
    const index = useRef(0)
    const width = window.innerWidth
  
    const [props, api] = useSprings(pages.length-1, i => ({
      x: i * width/3,
      scale: 1,
      display: 'block',
    }))
    const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel }) => {
        
        
      if (active && Math.abs(mx) > width / 4) {
        index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, pages.length - 2)
        setBackground(pages[index.current])
        cancel()
      }
      api.start(i => {
        if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
        const x = (i - index.current) * width/3 + (active ? mx : 0)
        const scale = active ? 1 - Math.abs(mx) / width / 2 : 1
        return { x, scale, display: 'block' }
      })
    })
    return (
      <div className="overflow-hidden -mt-[0px]" style={{width: '100%', height: '100%'}}>
        {props.map(({ x, display, scale }, i) => (
          <animated.div className="absolute touch-none p-2 -mt-[2px]" {...bind()} key={i} style={{ display, x, width: '100%', height: '100%' }} >
            <animated.div className="relative touch-none bg-cover bg-no-repeat bg-center rounded-full mx-auto p-10 aspect-square" style={{ scale, backgroundImage: `url(${pages[i]})`, width: 'auto', height: '100%' }} />
          </animated.div>
        ))}
        <animated.div className="absolute rounded-full aspect-square border left-1/2 -translate-x-[48%] touch-none pointer-events-none" style={{ width: 'auto', height: '96%' }} />
      </div>
    )
}

export default BackPager;