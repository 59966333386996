import { motion } from "framer-motion";

export default function Loading(){

    return (
        <>
        
            <div className='h-screen w-screen bg-[#0253db] absolute' style={{zIndex: 10000}}>

                <div className="absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2">
                    <motion.img
                        src="./assets/pastelitoLOW.png" alt="pinguino" className="size-12"
                        initial={{ scale: 1, y: '-100%' }}
                        animate={{ rotate: 360 }}
                        transition={{ repeat: Infinity, duration: 5}}
                    />
                </div>

            
            
           </div>
        </>
      )

  }