function throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function() {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function() {
          if ((Date.now() - lastRan) >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    }
  }
  
  window.addEventListener("mousemove", throttle(function (e) {
    if(window.location.pathname === '/celebracion' || window.location.pathname === '/celebracion/step-1' || window.location.pathname === '/celebracion/step-2'){
      if((window.innerWidth < e.clientX + 20 ) || (window.innerHeight < e.clientY + 20 )) return
      var to_append = document.getElementsByClassName('loader-container')[0];
      var parent_div = document.createElement('div');
      parent_div.className = "loader-container";
      var inner_div = document.createElement('div');
      inner_div.className = "loader";
      parent_div.appendChild(inner_div);
      document.body.appendChild(parent_div);
    
      parent_div.style.left = (e.clientX - 50) + 'px';
      parent_div.style.top = (e.clientY - 50) + 'px';
    
      if (document.getElementsByClassName('loader-container').length > 10) {
        document.body.removeChild(to_append);
      }
    }
  }, 150));

document.addEventListener('DOMContentLoaded', function() {
    // Función para desactivar clic derecho
    document.addEventListener('contextmenu', function(event) {
        event.preventDefault();
    });

    // Función para desactivar eventos de ratón en elementos que no sean enlaces ni botones
    document.addEventListener('mousedown', function(event) {
        var target = event.target;

        // Verifica si el elemento no es un enlace ni un botón
        if (!target.matches('a') && !target.matches('button') && !target.matches('input') && !target.matches('select') && !target.matches('textarea')) {
            event.preventDefault();
        }
    });
});

document.addEventListener('DOMContentLoaded', function() {
  const video = document.querySelector('.myVideo');
  if (video) {
    video.setAttribute('playsinline', '');
    video.setAttribute('webkit-playsinline', '');
    video.controls = false;
  }
});