import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    useMotionValue,
    useVelocity,
    useAnimationFrame,
    useAnimate
  } from "framer-motion";
import { wrap } from "@motionone/utils";
import { AnimatedText } from "./helpers/AnimatedText";
import Loading from "./helpers/Loading";

interface ParallaxProps {
    children: string;
    baseVelocity: number;
}

function ParallaxText({ children, baseVelocity = 100 }: ParallaxProps) {

  const [textColor, setTextColor] = useState("text-[#ffffff]");

  useEffect(()=>{
    change()
  }, [])
  

  function change(){
    
    
    const interval = setInterval(() => {
      setTextColor("text-[#035be0]")
    }, 3000);

    let interval2: string | number | NodeJS.Timer | undefined = undefined
    setTimeout(() => {
      interval2 = setInterval(() => {
        setTextColor("text-[#ffffff]")
      }, 3000);
    }, 1500);

    
    return () => {
      clearInterval(interval)
      clearInterval(interval2)
    };
    
  }

    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
      damping: 50,
      stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
      clamp: false
    });
  
    /**
     * This is a magic wrapping for the length of the text - you
     * have to replace for wrapping that works for you or dynamically
     * calculate
     */
    const x = useTransform(baseX, (v) => `${wrap(-5, -45, v)}%`);
  
    const directionFactor = useRef<number>(1);
    useAnimationFrame((t, delta) => {
      let moveBy = directionFactor.current * baseVelocity * (delta / 1000);
  
      /**
       * This is what changes the direction of the scroll once we
       * switch scrolling directions.
       */
      if (velocityFactor.get() < 0) {
        directionFactor.current = -1;
      } else if (velocityFactor.get() > 0) {
        directionFactor.current = 1;
      }
  
      moveBy += directionFactor.current * moveBy * velocityFactor.get();
  
      baseX.set(baseX.get() + moveBy);
    });
  
    /**
     * The number of times to repeat the child text should be dynamically calculated
     * based on the size of the text and viewport. Likewise, the x motion value is
     * currently wrapped between -20 and -45% - this 25% is derived from the fact
     * we have four children (100% / 4). This would also want deriving from the
     * dynamically generated number of children.
     */
    return (
      <div className="parallax">
        <motion.div className="scroller" style={{ x }}>
          <h1 style={{ marginTop: '-0%', WebkitTextStroke: '5px', WebkitTextStrokeColor: '#fff', scale:'1 1.5'}} className={`${textColor} opacity-50 font-ttBold text-center ml-20 leading-relaxed text-[180px] md:text-[220px] lg:text-[300px]`}>{children}</h1>
          <h1 style={{ marginTop: '-0%', WebkitTextStroke: '5px', WebkitTextStrokeColor: '#fff', scale:'1 1.5'}} className={`${textColor} opacity-50 font-ttBold text-center ml-20 leading-relaxed text-[180px] md:text-[220px] lg:text-[300px]`}>{children}</h1>
          <h1 style={{ marginTop: '-0%', WebkitTextStroke: '5px', WebkitTextStrokeColor: '#fff', scale:'1 1.5'}} className={`${textColor} opacity-50 font-ttBold text-center ml-20 leading-relaxed text-[180px] md:text-[220px] lg:text-[300px]`}>{children}</h1>
          <h1 style={{ marginTop: '-0%', WebkitTextStroke: '5px', WebkitTextStrokeColor: '#fff', scale:'1 1.5'}} className={`${textColor} opacity-50 font-ttBold text-center ml-20 leading-relaxed text-[180px] md:text-[220px] lg:text-[300px]`}>{children}</h1>
          <h1 style={{ marginTop: '-0%', WebkitTextStroke: '5px', WebkitTextStrokeColor: '#fff', scale:'1 1.5'}} className={`${textColor} opacity-50 font-ttBold text-center ml-20 leading-relaxed text-[180px] md:text-[220px] lg:text-[300px]`}>{children}</h1>
        </motion.div>
      </div>
    );
}

export default function Home(){

  const [scope, animate] = useAnimate()
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const mainCont = useRef<HTMLDivElement>(document.createElement("div"))

  async function sequence() {
    await animate([
        [scope.current, {scale: 1, y: '-50%' }, {duration: 0.1}],
        [scope.current, {scaleX: 1.8, scaleY: 0.3, y: '-10%' }, {duration: 0.2}],
        [scope.current, {scaleX: 0.7, scaleY: 0.9, y: '-60%' }, {duration: 0.1}],
        [scope.current, {scaleX: 0.3, scaleY: 1, y: '-80%' }, {duration: 0.2}],
        [scope.current, {scaleX: 0.7, scaleY: 0.7, y: '-30%' }, {duration: 0.1}],
        [scope.current, {scaleX: 1.1, scaleY: 0.3, y: '-20%' }, {duration: 0.1}],
        [scope.current, {scaleX: 0.7, scaleY: 0.9, y: '-70%' }, {duration: 0.3}],
        [scope.current, {scaleX: 1, scaleY: 1, y: '-65%' }, {duration: 0.3}]
    ])

  }

  useEffect(() => {
    window.scrollTo(0, 0)
    sequence()
  }, []);

    return (
        <>

        {!isPageLoaded && <Loading/>}
            <video preload="metadata" autoPlay muted loop className="myVideo pointer-events-none" style={{minHeight: mainCont.current.scrollHeight || '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back1R.mp4' type="video/mp4" />
            </video>

            <div className="flex flex-row min-h-svh items-center justify-center overflow-visible pb-20" ref={mainCont}>
                <div className="overflow-hidden">
                    <div className="relative mt-20">
                        <ParallaxText baseVelocity={-1}>FELICIDADES</ParallaxText>
                        <motion.img
                          src="./assets/pastelito.png" alt="pinguino" className="absolute size-48 md:size-64 lg:size-80 top-1/2 right-1/2"
                          style={{ x: '50%', y: '-65%' }}
                          initial={{ scale: 1, y: '-100%' }}
                          animate={{ rotate: 360 }}
                          transition={{ repeat: Infinity, duration: 50}}
                          ref={scope}
                        />
                    </div>
                    <AnimatedText
                      el="p"
                      text={[
                        "Crea un pastelito personalizado para celebrar el cumpleaños de tus amigos o quien tú quieras. Celebremos juntos, solo porque nos gusta.",
                        " "
                      ]}
                      stagger={0.25}
                      delay={1}
                      className="text-white text-center font-light text-sm md:text-md lg:text-xl w-4/5 md:w-3/5 lg:w-1/3 mx-auto lg:-mt-20"
                    />

                    <div className="flex flex-row mt-10 mb-8 space-x-4 md:space-x-12 justify-center w-80 md:w-full mx-auto">
                      <div className="text-center">
                        <img src="./assets/registro.png" className="w-12 md:w-20 h-auto mb-2" />
                        <br />
                        <p className="font-bloomer text-sm md:text-lg lg:text-2xl text-[#ebff00] uppercase">1. REGISTRA TUS DATOS</p>
                      </div>
                      <div className="text-center">
                        <img src="./assets/pastelVela.png" className="w-[1.65rem] md:w-11 h-auto mb-2" />
                        <br />
                        <p className="font-bloomer text-sm md:text-lg lg:text-2xl text-[#ebff00] uppercase">2. Crea tu pingüino cumpleañero</p>
                      </div>
                      <div className="text-center">
                        <img src="./assets/comparte.png" className="w-12 md:w-20 h-auto mb-2" />
                        <br />
                        <p className="font-bloomer text-sm md:text-lg lg:text-2xl text-[#ebff00] uppercase">3. Y comparte</p>
                      </div>
                    </div> 

                    <div className="text-center mx-auto mt-10 w-full mb-4 z-50">
                        <Link to="/celebracion-1">
                          <motion.button 
                                type="submit"
                                className="cursor-pointer bg-[#ebff00] hover:bg-[#eaff00e5] text-[#0353da] text-xl md:text-3xl lg:text-5xl font-bloomer py-3 md:py-5 px-8 md:px-12 rounded-full uppercase"
                                animate={{ scale: [1, 1.2, 1]}}
                                transition={{
                                  type: "linear",
                                  duration: 3,
                                  repeat: Infinity
                                }}
                            >
                                CONTINUAR
                          </motion.button>
                        </Link>
                    </div>  
                </div>
            </div>
        </>
       
    )
}