import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toPng } from 'html-to-image';
import WavyText from "./helpers/WavyText";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { useStore } from "../store";
import { AutoTextSize } from "auto-text-size";
import Loading from "./helpers/Loading";
import Sticker from "./helpers/Sticker";
import StickerM from "./helpers/StickerM";
import { useGesture } from "@use-gesture/react";
import Wheel from "@uiw/react-color-wheel";
import { hsvaToHex } from '@uiw/color-convert';
import BackPager from "./helpers/BackPager";
import { AnimatedText } from "./helpers/AnimatedText";
import Moveable from "react-moveable";
const domtoimage = require('dom-to-image');

interface textState {
    text?: string;
    width?: string;
    height?: string;
    x?: number;
    y?: number;
    rotation?: number;
    scale?: number;
    color?: string;
    font?: string;
    fontStyle?: string;
}

export default function Step4(){


    const { background, setBackground, stickers, name, addPastelito, addSticker, setImage, selectSticker, text, setText } = useStore();
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const navigate = useNavigate();
    const [texts, setTexts] = useState(false);
    const [sticks, setStickers] = useState(false);
    const [backs, setBacks] = useState(false);
    const [isCenter, setIsCenter] = useState(true);
    const [openColor, setOpenColor] = useState(false);
    const [openFont, setOpenFont] = useState(false);
    const [openFontStyle, setOpenFontStyle] = useState(false);
    const [font, setFont] = useState('font-anton');
    const [fontStyle, setFontStyle] = useState('normal');
    const [isCapturing, setIsCapturing] = useState(false);
    const mainCont = useRef<HTMLDivElement>(document.createElement("div"))
    const video = useRef<HTMLVideoElement>(document.createElement("video"))
    const [backsPage, setBacksPage] = useState(0);
    const [isMobile] = useState<boolean>(window.innerWidth <= 1024);
    const [hsva, setHsva] = useState({ h: 214, s: 43, v: 90, a: 1 });
    let backsDict = [
        [
            './assets/backs/Fondo1.png',
            './assets/backs/Fondo2.png',
            './assets/backs/Fondo3.png',
            './assets/backs/Fondo4.png'
        ],
        [
            './assets/backs/Fondo5.png',
            './assets/backs/Fondo6.png',
            './assets/backs/Fondo7.png',
            './assets/backs/Fondo8.png'
        ]
    ];

    const x = useMotionValue(text.x);
    const y = useMotionValue(text.y);
    const scale = useMotionValue(text.scale);
    const rotate = useMotionValue(text.rotation);
    const textRef = useRef<HTMLDivElement>(null);
    const [state, setState] = useState<textState>({...text})  

    function handlePageChange(increment: number){
        if(backsPage + increment >= backsDict.length){
            setBacksPage(0)
        }else if(backsPage + increment < 0){
            setBacksPage(backsDict.length-1)
        }else{
            setBacksPage(backsPage + increment)
        }
    }

    const mainContRef = useRef(document.createElement("div"));

    const deleteRef = useRef<HTMLDivElement>(document.createElement("div"));

    useEffect(()=>{
        window.scrollTo(0, 0)
        if(!name){
            navigate("/celebracion");
        }

        setState({ ...text })

        //setTimeout(() => {
            if(stickers.find(x => x.id === 99)) return
            addPastelito({
                id: 99,
                size: (mainContRef.current.clientWidth/2)+'',
                x: (mainContRef.current.clientWidth/2)-(mainContRef.current.clientWidth/4),
                y: (mainContRef.current.clientHeight/2)-(mainContRef.current.clientWidth/4),
                url: './assets/pastelSR.png',
                z: 0,
                scale: 1,
                rotation: 0,
                selected: false
            })
        //}, 300);

        setTimeout(() => {
           video.current.style.minHeight = mainCont.current.scrollHeight+'px'
        }, 3000);

    },[])

    useGesture(
        {
            onDrag: ({ offset: [dx, dy] }) => {
                x.set(dx);
                y.set(dy);
                
            },
            onPinch: ({ offset: [s, r], memo }) => {
                scale.set(s)
                rotate.set(r)
                return memo
            },
            onDragEnd:()=>{
                setText({...text, scale: scale.get(), rotation: rotate.get(), x: x.get(), y: y.get()})
            }
        },
        {
            target: textRef,
            drag: { from: () => [x.get(), y.get()] },
            pinch: { scaleBounds: { min: 0.5, max: 10 } },
            eventOptions: { passive: false },
        }
    );

    const exportAsImage2 = async (el:any) => {
        setIsCapturing(true)
        setIsPageLoaded(false)
        setTimeout(async () => {
            const width = el.clientWidth;
            const height = el.clientHeight;
            domtoimage.toPng(el, {
                quality: 1,
                width: width,
                height: height,
            })
            .then((dataUrl:any) => {
                setImage(dataUrl);
                navigate("/celebracion-7");
            })
            .catch((error:any) => {
                console.error('Error capturing image', error);
            });
        }, 100);
        
    }

    const exportAsImage = async (el:any) => {
        setIsCapturing(true)
        setIsPageLoaded(false)
        selectSticker({id: 9999, z: 10})
        await toPng(el)
        await toPng(el)
        await toPng(el)
        await toPng(el, {
                cacheBust: true,
                width: el.clientWidth,
                height: el.clientHeight,
                style: { backgroundColor: 'white' } 
             })
                .then((dataUrl) => {
                    //fetch('/subir-imagen', {
                    //    method: 'POST',
                    //    headers: { 'Content-Type': 'application/json' },
                    //    body: JSON.stringify({ imagen: dataUrl })
                    //})
                    //  .then(response => response.json())
                    //  .then(data => {
                     //   if (data.url) {
                            setImage(dataUrl);
                            navigate("/celebracion-7");
                    //    } else {
                          // Manejar el error
                    //    }
                    //});
                })
                .catch((err) => {
                    console.log(err);
                });
        
    }

    function handleSubmit(text_: string){
        if(text_.length > 280) return
        setText({...text, text:text_})
    }

    useEffect(()=>{
        setState({...text})
    },[text])


    return (
        <>
            {!isPageLoaded && <Loading/>}

            <video ref={video} preload="metadata" autoPlay muted loop className="myVideo pointer-events-none" style={{minHeight: mainCont.current.scrollHeight || '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back5R.mp4' type="video/mp4" />
            </video>
            <div className="flex flex-row min-h-svh items-center justify-center z-50 overflow-hidden pb-40" ref={mainCont}>
                <div className="hidden lg:block basis-1/3 pl-20">
                    <div className="">
                        <WavyText x={-50} y={100} z={120} className="text-[#ebff00] font-ttBold text-6xl xl:text-8xl inline-block text-center uppercase scale-y-125 drop-shadow justify-center" text="¡AHORA SÍ!" replay={true} />
                        {/* <WavyText x={50} y={-100} z={-180} className="text-[#ebff00] font-ttBold text-6xl xl:text-8xl inline-block text-center uppercase scale-y-125 drop-shadow leading-[5rem] xl:leading-[8rem] justify-center"  text="UN FONDO" replay={true} /> */}
                    </div>
                    <div className="flex flex-row mt-6">
                            <AnimatedText
                                    el="p"
                                    text={[
                                        "Elige un fondo, ponle un mensaje y decora con stickers.",
                                        "Usa hasta 280 caracteres."
                                    ]}
                                    stagger={0.3}
                                    delay={0.5}
                                    className="text-white text-center font-light basis-[55%] text-sm md:text-md lg:text-xl mx-auto"
                            />
                    </div>
                    <div className="flex flex-row mt-10 space-x-6 xl:space-x-12 justify-center">
                        <Link to="/celebracion-3">
                            <motion.button 
                                type="submit"
                                className="cursor-pointer opacity-85 bg-[#ffffff] hover:opacity-100 text-[#7136d4] text-md xl:text-2xl font-bloomer py-5 px-8 rounded-full uppercase basis-1/4 transition-all"
                                animate={{ scale: [0.8, 1.4, 0.8]}}
                                transition={{
                                  type: "linear",
                                  duration: 3,
                                  repeat: Infinity
                                }}
                            >
                                ANTERIOR
                            </motion.button>
                        </Link>
                        {/* <Link to="/step-5"> */}
                            <motion.button 
                                type="submit"
                                className="cursor-pointer opacity-85 bg-[#ffffff] hover:opacity-100 text-[#7136d4] text-md xl:text-2xl font-bloomer py-5 px-8 rounded-full uppercase basis-1/4 transition-all"
                                animate={{ scale: [1.4, 0.8, 1.4]}}
                                transition={{
                                  type: "linear",
                                  duration: 3,
                                  repeat: Infinity
                                }}
                                onClick={() => exportAsImage(mainContRef.current)}
                           >
                                SIGUIENTE
                            </motion.button>
                        {/* </Link> */}
                    </div>
                </div>
                <motion.div 
                    className="basis-full lg:basis-1/3" 
                    initial={{y: '50%', opacity: 0}}
                    animate={{ y: '5%', opacity: 1}}
                    transition={{ duration: 1}}
                    style={{zIndex:1000}}
                >

                    <div className="flex flex-row justify-center my-5 space-x-2 " style={{zIndex:5000}}>
                        <img onClick={() => {setTexts(!texts); setStickers(false); setBacks(false)}} className={`w-10 h-6 lg:w-16 lg:h-10 hover:scale-110 transition-all cursor-pointer ${texts ? 'hue-rotate-90': ''}`} src="./assets/texto.png" alt="" />
                        <img onClick={() => {setStickers(!sticks); setTexts(false); setBacks(false)}} className={`w-10 h-6 lg:w-16 lg:h-10 hover:scale-110 transition-all cursor-pointer ${sticks ? 'hue-rotate-90': ''}`} src="./assets/stickers.png" alt="" />
                        <img onClick={() => {setBacks(!backs); setStickers(false); setTexts(false)}} className={` lg:hidden w-10 h-6 hover:scale-110 transition-all cursor-pointer ${backs ? 'hue-rotate-90': ''}`} src="./assets/backgrounds.png" alt="" />
                    </div>
                    <div 
                        className={`${!isCapturing ? 'rounded-3xl  mx-auto': 'mx-0'} h-[70vh] w-auto lg:h-auto lg:w-[80%] xl:w-[70%] 2xl:w-[60%] border-2 border-[#ffffff50] transition-all bg-cover relative overflow-hidden`} 
                        style={{backgroundImage: `url(${background})`, zIndex:1000, aspectRatio: '9/16'}}
                        ref={mainContRef}
                        >

                                   

                            {!isMobile && stickers.map(function(object, i){
                                return <Sticker
                                        key={i}
                                        id={object.id}
                                        size={object.size}
                                        x={object.x}
                                        y={object.y}
                                        url={object.url}
                                        z={object.z}
                                        scale={object.scale}
                                        rotation={object.rotation}
                                        deleteRef={deleteRef}
                                        selected={object.selected}
                                    />
                                    
                            })}

                            {isMobile && stickers.map(function(object, i){
                                return <StickerM
                                        key={i}
                                        id={object.id}
                                        size={object.size}
                                        x={object.x}
                                        y={object.y}
                                        url={object.url}
                                        z={object.z}
                                        scale={object.scale}
                                        rotation={object.rotation}
                                        deleteRef={deleteRef}
                                    />
                                    
                            })}

                           

                            <div>
                                <motion.div
                                    ref={textRef}
                                    style={{
                                        touchAction: 'none',
                                        position: 'absolute',
                                        width: text.width,
                                        height: text.height,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        x,
                                        y,
                                        scale,
                                        rotate: useTransform(rotate, r => `${r}deg`),
                                        zIndex: 5000
                                    }}
                                >
                                    {<div className={`w-full h-full mx-auto justify-center`}>
                                        <AutoTextSize
                                            className={` ${text.font} align-middle ${text.fontStyle}`} style={{color: text.color, textAlign: isCenter?'center':'justify'}}
                                            minFontSizePx={12}
                                            mode="box"
                                        >
                                            {`${isMobile ? text.text || '' : ''}`}
                                        </AutoTextSize>
                                    </div>}
                                </motion.div>
                            </div>

                            {text.text && !isMobile &&<div>
                                <div
                                    ref={textRef}
                                    style={{
                                        position: 'absolute',
                                        left: state.x,
                                        top: state.y,
                                        width: state.width,
                                        height: state.height,
                                        transform: `rotate(${state.rotation}deg) scale(${state.scale})`,
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        zIndex: 5000,
                                        cursor: 'grab',
                                        touchAction: 'none'
                                    }}
                                >
                                    {<div className={`w-full h-full mx-auto justify-center`}>
                                        <AutoTextSize
                                             className={` ${text.font} align-middle ${text.fontStyle}`} style={{color: text.color, textAlign: isCenter?'center':'justify'}}
                                             minFontSizePx={12}
                                             mode="box"
                                        >
                                            {`${text.text}`}
                                        </AutoTextSize>
                                    </div>}
                                </div>
                                <Moveable
                                    target={textRef.current}
                                    draggable={!isCapturing}
                                    rotatable={!isCapturing}
                                    scalable={!isCapturing}
                                    hideDefaultLines={isCapturing}
                                    origin={false}
                                    renderDirections={["nw", "ne", "sw", "se"]}
                                    onRotate={e=>{
                                        setState({...state, rotation: e.rotation})
                                    }}
                                    onDrag={e=>{
                                        setState({...state, x: e.left, y: e.top})
                                    }}
                                    onScale={e=>{
                                        setState({...state, scale: e.scale[0]})
                                    }}
                                    onDragEnd={e=>{
                                        setText({...state})
                                    }}
                                    onScaleEnd={e=>{
                                        setText({...state})
                                    }}
                                    onRotateEnd={e=>{
                                        setText({...state})
                                    }}
                                    keepRatio={true}
                                />
                            </div>}

                        {!isCapturing && backs && <motion.div 
                            className="relative mt-[0svh]" 
                            initial={{y: '-50%', opacity: 0}}
                            animate={{ y: '0%', opacity: 1}}
                            transition={{ duration: 1}}
                            style={{zIndex: 5000}}
                        >
                            <div className="bg-[#00000030] overflow-y-auto overflow-x-hidden rounded-3xl min-h-[15svh] max-h-[15svh] w-[100%] mx-auto border-2 border-[#ffffff50] transition-all">
                                <BackPager/>
                            </div>
                        
                        </motion.div>}

                        {!isCapturing && sticks && <motion.div 
                            className="relative mt-[10svh]" 
                            initial={{y: '50%', opacity: 0}}
                            animate={{ y: '0%', opacity: 1}}
                            transition={{ duration: 1}}
                            style={{zIndex: 5000}}
                        >
                            <div className={`grid grid-cols-3 gap-4 p-6 bg-[#00000030] overflow-y-auto overflow-x-hidden rounded-3xl h-auto w-[100%] mx-auto border-2 border-[#ffffff50] transition-all`} style={{aspectRatio: '9/16'}}>
                                {[...Array(64)].map((e, i) => 
                                    {
                                        return <div className="aspect-square"><motion.img 
                                            key={i}
                                            className="cursor-pointer w-100 h-100" src={`./assets/stickers_placeholders/Sticker ${i+1}.png`} alt="" 
                                            animate={{rotateZ: [-45, 45]}}
                                            transition={{duration: 3, repeat: Infinity, repeatType:'mirror'}}
                                            onClick={() => {addSticker({url: `./assets/stickers/Sticker ${i+1}.png`, z: 0}); setStickers(false)}}
                                        /></div>
                                    })
                                }
                                
                            </div>
                            
                        </motion.div>}

                        {!isCapturing && texts && <motion.div 
                            className="relative mt-[10svh]" 
                            initial={{y: '50%', opacity: 0}}
                            animate={{ y: '0%', opacity: 1}}
                            transition={{ duration: 1}}
                            style={{zIndex: 5000}}
                            
                        >
                        <div className={`bg-[#00000030] overflow-auto rounded-3xl h-auto w-[100%] mx-auto border-2 border-[#ffffff50] transition-all`} style={{aspectRatio: '9/16'}}>
                            <p className="absolute top-2 right-4 text-white font-bold cursor-pointer" onClick={() => {setTexts(false)}}>Listo</p>
                            <div className="flex flex-row justify-center mt-8 space-x-4" style={{zIndex: 100}}>
                                <img className={`transition-all hover:scale-125 cursor-pointer w-10 h-8 p-1`} src={`${isCenter ? './assets/centered.png':'./assets/justify.png'}`} alt="" onClick={()=>setIsCenter(!isCenter)}/>
                                <div className="relative">
                                    <img className="transition-all hover:scale-125 cursor-pointer w-8 h-8 p-1" src="./assets/circle.png" alt="" onClick={() => {setOpenColor(!openColor); setOpenFont(false); setOpenFontStyle(false)}}/>
                                    {openColor && <Wheel onTouchEnd={()=> setOpenColor(false)} color={hsva} onChange={(color) => {setHsva({ ...hsva, ...color.hsva }); setText({...text, color: hsvaToHex(hsva) })}} className="!absolute top-[80%] right-1/2 translate-x-1/2 transition-all border-2 rounded-full" />}
                                </div>
                                <div className="relative">
                                    <img className="transition-all hover:scale-125 cursor-pointer w-7 h-19 p-1 mx-auto mb-3" src="./assets/T.png" alt="" onClick={() => {setOpenFont(!openFont); setOpenColor(false); setOpenFontStyle(false)}}/>
                                    {openFont &&<motion.ul initial={{opacity: 0}} animate={{opacity: 1}} className={`list-none transition-all border-[#eaff02] ${openFont ? 'border': 'border-0'} !absolute top-[80%] right-1/2 translate-x-1/2 transition-all z-50 bg-[#46464669]`}>
                                        <li className="text-lg text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-2 cursor-pointer transition-all font-anton" onClick={() => {setText({...text, font: 'font-anton'}); setOpenFont(false)}}>Anton</li>
                                        <li className="text-lg text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-2 cursor-pointer transition-all font-pacifico" onClick={() => {setText({...text, font: 'font-pacifico'}); setOpenFont(false)}}>Pacífico</li>
                                        <li className="text-2xl text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-2 cursor-pointer transition-all font-caveat" onClick={() => {setText({...text, font: 'font-caveat'}); setOpenFont(false)}}>Caveat</li> 
                                        <li className="text-xl text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-2 cursor-pointer transition-all font-jersey" onClick={() => {setText({...text, font: 'font-jersey'}); setOpenFont(false)}}>Jersey</li>
                                        <li className="text-lg text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-2 cursor-pointer transition-all font-varela" onClick={() => {setText({...text, font: 'font-varela'}); setOpenFont(false)}}>Varela</li>
                                        <li className="text-lg text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-2 cursor-pointer transition-all font-pop" onClick={() => {setText({...text, font: 'font-pop'}); setOpenFont(false)}}>Pop</li>
                                    </motion.ul>}
                                </div>
                                <div className="relative">
                                    <p className="transition-all hover:scale-125 cursor-pointer p-1 mx-auto mb-3 font-tt text-3xl text-white" onClick={() => {setOpenFontStyle(!openFontStyle); setOpenColor(false); setOpenFont(false)}}>Aa</p>
                                    {openFontStyle &&<motion.ul initial={{opacity: 0}} animate={{opacity: 1}} className={`list-none transition-all border-[#eaff02] ${openFontStyle ? 'border': 'border-0'} space-y-2 !absolute top-[80%] right-1/2 translate-x-1/2 transition-all z-50 bg-[#46464669]`}>
                                        <li className="text-lg text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-6 mx-1 cursor-pointer transition-all font-anton normal" onClick={() => {setText({...text, fontStyle: 'normal'}); setOpenFontStyle(false)}}>Aa</li>
                                        <li className="text-lg text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-6 mx-1 cursor-pointer transition-all font-anton tipo1" onClick={() => {setText({...text, fontStyle: 'tipo1'}); setOpenFontStyle(false)}}>Aa</li>
                                        <li className="text-lg text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-6 mx-1 cursor-pointer transition-all font-anton tipo2" onClick={() => {setText({...text, fontStyle: 'tipo2'}); setOpenFontStyle(false)}}>Aa</li> 
                                        <li className="text-lg text-white text-center hover:bg-[#eaff02] hover:text-[#fa0100] px-6 mx-1 cursor-pointer transition-all font-anton tipo3" onClick={() => {setText({...text, fontStyle: 'tipo3'}); setOpenFontStyle(false)}}>Aa</li>
                                    </motion.ul>}
                                </div>
                            </div>
                            <motion.textarea 
                                id="name"
                                name="name"
                                className="bg-[#fff] p-3 mt-3 w-5/6 rounded-3xl focus:outline-none text-left text-lg text-[#000] block mx-auto leading-8 opacity-90"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1}}
                                rows={5}
                                value={text.text}
                                onInput={(e:any) => handleSubmit(e.target['value'])}
                            />
                        </div>
                        </motion.div>}

                        {!isCapturing && !backs && !texts && !sticks &&<div className="absolute w-12 h-8 left-1/2 -translate-x-1/2 bottom-2" ref={deleteRef} style={{zIndex: 5000}}>
                            <img className={`w-full h-full transition-all mx-auto align-middle pointer-events-none block`} src="./assets/trash.png" alt="" />
                        </div>}

                    </div>

                    <div className="flex flex-row justify-center my-5 space-x-4 lg:hidden" style={{zIndex:1001}}>
                        <Link to="/celebracion-3">
                            <motion.button 
                                type="submit"
                                className="cursor-pointer opacity-85 bg-[#ffffff] hover:opacity-100 text-[#7136d4] text-sm font-bloomer py-2 px-5 rounded-full uppercase basis-1/4 transition-all"
                                animate={{ scale: [0.8, 1.1, 0.8]}}
                                transition={{
                                type: "linear",
                                duration: 3,
                                repeat: Infinity
                                }}
                            >
                                ANTERIOR
                            </motion.button>
                        </Link>
                        <motion.button 
                            type="submit"
                            className="cursor-pointer opacity-85 bg-[#ffffff] hover:opacity-100 text-[#7136d4] text-sm font-bloomer py-2 px-5 rounded-full uppercase basis-1/4 transition-all"
                            animate={{ scale: [1.1, 0.8, 1.1]}}
                            transition={{
                            type: "linear",
                            duration: 3,
                            repeat: Infinity
                            }}
                            onClick={() => exportAsImage(mainContRef.current)}
                    >
                            SIGUIENTE
                        </motion.button>
                    </div>
                </motion.div>

                <div className="hidden lg:block basis-1/3 relative">
                    
                    
                    <div className="z-0">
                        <motion.div className="h-[100svh] relative" style={{backgroundImage: `url(./assets/ellipse.png)`}}
                            
                        >
                            <motion.img src="./assets/ellipse.png" alt="" className="absolute h-[100svh] w-full -translate-x-1/2" 
                                initial={{x: '100%', y: '0%'}}
                                animate={{ x: '-50%', y: '0%'}}
                                transition={{ duration: 1.5}}
                            />
                            <div onClick={() => setBackground(backsDict[backsPage][0])} className="cursor-pointer size-32 absolute rounded-full translate-x-1/2 -translate-y-1/2 transition-all" style={{top: '15%', right: '58%'}}>
                                <motion.div className="w-full h-full rounded-full bg-cover" style={{backgroundImage: `url(${backsDict[backsPage][0]})`}}
                                    initial={{x: '0%', y: '100svh'}}
                                    animate={{ x: ['-70%', '48%', '38%', '0%'], y: '0%', scale: [1, 1.1, 1]}}
                                    transition={{ duration: 1.5, delay: 1, scale:{duration: 3, repeat: Infinity}}}
                                >
                                    <div className="border size-36 rounded-full !-translate-x-[8px] !-translate-y-[8px]"></div>
                                </motion.div>
                            </div>
                            <div onClick={() => setBackground(backsDict[backsPage][1])} className="cursor-pointer size-32 absolute rounded-full translate-x-1/2 -translate-y-1/2 transition-all" style={{top: '38%', right: '51.5%'}}>
                                <motion.div className="w-full h-full rounded-full !bg-cover" style={{backgroundImage: `url(${backsDict[backsPage][1]})`}}
                                    initial={{x: '0%', y: '100svh'}}
                                    animate={{ x: ['-200%', '10%', '0%'], y: '0%', scale: [1, 1.1, 1]}}
                                    transition={{ duration: 1.5, delay: 1.1, scale:{duration: 3, repeat: Infinity}}}
                                >
                                    <div className="border size-36 rounded-full -translate-x-[8px] -translate-y-[8px]"></div>
                                </motion.div>
                            </div>
                            <div onClick={() => setBackground(backsDict[backsPage][2])} className="cursor-pointer size-32 absolute rounded-full translate-x-1/2 -translate-y-1/2 transition-all" style={{top: '61%', right: '51.5%'}}>
                                <motion.div className="w-full h-full rounded-full bg-cover" style={{backgroundImage: `url(${backsDict[backsPage][2]})`}}
                                    initial={{x: '0%', y: '100svh'}}
                                    animate={{ x: ['-200%', '0%'], y: '0%', scale: [1, 1.1, 1]}}
                                    transition={{ duration: 1.5, delay: 1.2, scale:{duration: 3, repeat: Infinity}}}
                                >
                                    <div className="border size-36 rounded-full -translate-x-[8px] -translate-y-[8px]"></div>
                                </motion.div>
                            </div>
                            <div onClick={() => setBackground(backsDict[backsPage][3])} className="cursor-pointer size-32 absolute rounded-full translate-x-1/2 -translate-y-1/2 transition-all" style={{top: '85%', right: '58%'}}>
                                <motion.div className="w-full h-full rounded-full bg-cover" style={{backgroundImage: `url(${backsDict[backsPage][3]})`}}
                                    initial={{x: '-0%', y: '100svh'}}
                                    animate={{ x: ['-200%', '0%'], y: '0%', scale: [1, 1.1, 1]}}
                                    transition={{ duration: 1.5, delay: 1.3, scale:{duration: 3, repeat: Infinity}}}
                                >
                                    <div className="border size-36 rounded-full -translate-x-[8px] -translate-y-[8px]"></div>
                                </motion.div>
                            </div>
                
                        </motion.div>
                    </div>

                    <div className="absolute mt-10 bottom-1/2 translate-y-1/2 right-[5%] lg:right-[15%]  xl:right-[20%] 2xl:right-[25%]">
                        <img onClick={() => {handlePageChange(-1)}} className={`cursor-pointer size-12 hover:scale-110 transition-all}`} src="./assets/arriba.png" alt="" />
                        <br />
                        <img onClick={() => {handlePageChange(1)}} className={`cursor-pointer size-12 hover:scale-110 transition-all mt-2`} src="./assets/abajo.png" alt="" />
                    </div>

                </div>
                  
            </div>

        </>
    )
}