import { Link, useNavigate } from "react-router-dom";
import WavyText from "./helpers/WavyText";
import { AnimatedText } from "./helpers/AnimatedText";
import { motion } from "framer-motion";
import Model from "./helpers/Model";
import { Suspense, useEffect, useRef, useState } from "react";
import { useStore } from "../store";
import Loading from "./helpers/Loading";

export default function Step8(){

    const navigate = useNavigate();
    const { name } = useStore();
    const mainCont = useRef<HTMLDivElement>(document.createElement("div"))
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    
    useEffect(()=>{
        window.scrollTo(0, 0)
        if(!name){
            navigate("/celebracion");
        }
    },[])

    const download = (what: string) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.download = what;
        
        fakeLink.href = `./assets/${what}.zip`;

        fakeLink.target = "_blank"
        
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        
        fakeLink.remove();
    };

    return (
        <>

            {!isPageLoaded && <Loading/>}

            <video preload="metadata" autoPlay muted loop className="hidden md:block myVideo pointer-events-none" style={{minHeight: mainCont.current.scrollHeight || '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back9R.mp4' type="video/mp4" />
            </video>
            <video preload="metadata" autoPlay muted loop className="block md:hidden myVideo pointer-events-none" style={{minHeight: mainCont.current.scrollHeight || '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back9v2R.mp4' type="video/mp4" />
            </video>
            <div className="flex flex-row min-h-screen xl:h-screen lg:items-center mx-10 md:mx-20 justify-center -mb-5 pb-20" ref={mainCont}>
                <div className="basis-full lg:basis-1/2 pl-0 xl:pl-20">

                <div className="w-full h-1/3 xl:h-1/2 block xl:hidden mt-20 relative">
                    <Suspense fallback={null}><Model fov={50} /></Suspense>
                    <img src="./assets/360.png" alt="" className="w-10 h-8 absolute bottom-0 right-0"/>
                </div>

                    <div className="w-full mx-auto z-0 text-center">
                        <WavyText x={-50} y={100} z={120} className="text-[#ebff00] font-ttBold text-3xl md:text-5xl  lg:text-7xl inline-block text-center uppercase scale-y-125 drop-shadow justify-center" text="¡AQUÍ HAY MÁS" replay={true} />
                        <WavyText x={50} y={-100} z={-180} className="text-[#ebff00] font-ttBold text-3xl md:text-5xl lg:text-7xl inline-block text-center uppercase scale-y-125 drop-shadow leading-[3rem] md:leading-[4rem] lg:leading-[7rem] justify-center" text="PARA CELEBRAR!" replay={true} />
                    </div>
                    <div className="flex flex-row justify-center z-50 w-full text-center">
                        <AnimatedText
                                el="p"
                                text={[
                                    "¡Qué lo abra! ¡Qué lo abra!"
                                ]}
                                stagger={0.3}
                                delay={0.8}
                                className="text-white justify-center font-light text-sm md:text-2xl basis-full xl:basis-2/3 mb-2"
                        />
                        
                    </div>
                    {/* <div className="flex flex-row justify-center xl:justify-start z-50 space-x-4 w-full mx-auto mt-4">
                        <div className="text-center">
                            <motion.button 
                                type="submit"
                                className="cursor-pointer bg-[#e35043] hover:bg-[#e35043cd] text-[#fff] text-sm md:text-xl lg:text-3xl font-bloomer py-2 px-8 rounded-full uppercase"
                                animate={{ scale: [0.8, 1, 0.8]}}
                                transition={{
                                  type: "linear",
                                  duration: 3,
                                  repeat: Infinity
                                }}
                            >
                                quiero
                            </motion.button>
                        </div>
                        
                    </div> */}
                    <div className="w-full mx-auto z-0 mt-0">
                        <WavyText x={-50} y={100} z={120} className="text-[#ebff00] font-bloomer text-xl md:text-3xl inline-block text-center drop-shadow justify-center" text="Descarga y celebra." replay={true} delay={1.2} />
                    </div>
                    {/* <div className="flex flex-row justify-center xl:justify-start mt-4 z-50 space-x-4 w-full mx-auto">
                        <AnimatedText
                                el="p"
                                text={[
                                    "Solo porque nos gusta hacer de una sorpresa una gran sorpresa, tenemos este Kit cumpleañero con todo lo que una fiesta necesita. Está listo para enviarlo al domicilio de quien celebras."
                                ]}
                                stagger={0.3}
                                delay={2}
                                className="text-white text-justify font-light text-xs md:text-xl basis-full xl:basis-2/3"
                        />
                        
                        
                    </div>
                    <div className="flex flex-row justify-center xl:justify-start mt-4 z-50 space-x-4 w-full mx-auto">
                        <div className=" text-center">
                            <motion.button 
                                type="submit"
                                className="cursor-pointer bg-[#e35043] hover:bg-[#e35043cd] text-[#fff] text-sm md:text-xl lg:text-3xl font-bloomer py-2 px-8 rounded-full uppercase"
                                animate={{ scale: [1, 0.8, 1]}}
                                transition={{
                                  type: "linear",
                                  duration: 3,
                                  repeat: Infinity
                                }}
                            >
                                vamos
                            </motion.button>
                        </div>
                        
                    </div>

                    <div className="w-full mx-auto z-0 mt-4 md:mt-12">
                        <WavyText x={-50} y={100} z={120} className="text-[#fff] font-thin text-xl md:text-2xl inline-block text-center drop-shadow justify-center xl:justify-start" text="3. Personaliza otro pastelito" replay={true} delay={2.2} />
                    </div>
                    <div className="flex flex-row justify-center xl:justify-start mt-4 z-50 space-x-4 w-full mx-auto">
                        <div className=" text-center">
                            <Link to="/step-2">
                                <motion.button 
                                    type="submit"
                                    className="cursor-pointer bg-[#e35043] hover:bg-[#e35043cd] text-[#fff] text-sm md:text-xl lg:text-3xl font-bloomer py-2 px-8 rounded-full uppercase"
                                    animate={{ scale: [0.8, 1, 0.8]}}
                                    transition={{
                                    type: "linear",
                                    duration: 3,
                                    repeat: Infinity
                                    }}
                                >
                                    Personalizar
                                </motion.button>
                            </Link>
                        </div>
                        
                    </div> */}

                    <div className="flex flex-row mt-10 mb-8 space-x-4 md:space-x-36 justify-center w-80 md:w-full mx-auto">
                      <div className="text-center">
                        <img src="./assets/pinguiners.png" className="w-[5.5rem] md:w-[11.2rem] h-auto mb-4" />
                        <br />
                        <motion.button 
                            type="submit"
                            className="cursor-pointer bg-[#ebff00] text-[#0d55e3] text-sm md:text-xl lg:text-3xl font-bloomer py-2 px-12 rounded-full uppercase"
                            animate={{ scale: [1, 0.8, 1]}}
                            transition={{
                            type: "linear",
                            duration: 3,
                            repeat: Infinity
                            }}
                            onClick={() => download('stickers')}
                        >
                            STICKERS
                        </motion.button>
                      </div>
                      <div className="text-center">
                        <img src="./assets/phone.png" className="w-[2.7rem] md:w-[5.5rem] h-auto mb-4" />
                        <br />
                        <motion.button 
                            type="submit"
                            className="cursor-pointer bg-[#ebff00] text-[#0d55e3] text-sm md:text-xl lg:text-3xl font-bloomer py-2 px-8 rounded-full uppercase"
                            animate={{ scale: [0.8, 1, 0.8]}}
                            transition={{
                            type: "linear",
                            duration: 3,
                            repeat: Infinity
                            }}
                            onClick={() => download('felicitaciones')}
                        >
                            FELICITACIONES
                        </motion.button>
                      </div>
                    </div> 

                </div>
                <div className="basis-1/2 h-full hidden xl:block relative">
                    <Suspense fallback={null}><Model fov={70} /></Suspense>
                    <img src="./assets/360.png" alt="" className="w-20 h-16 absolute bottom-1/4 right-1/4"/>
                </div>
                
            </div>
              
            
        </>
    )
}