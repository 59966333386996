import { Canvas } from '@react-three/fiber'
import { useGLTF, Environment, ContactShadows, Text3D, OrbitControls, Center, useTexture, Grid } from '@react-three/drei'
import { motion } from 'framer-motion-3d'
import { Suspense, useRef } from 'react'
import { useStore } from '../../store'

export default function Model({fov}:{fov:number}) {

  return (
    <Canvas shadows dpr={[1, 1.5]} camera={{ position: [-3.5, 4, 1], fov: fov}}>
      <OrbitControls makeDefault  enableZoom={false} enableDamping={false} enablePan={false} enableRotate={true}/>
      <Suspense fallback={null}>
        <ambientLight intensity={5} />
        <directionalLight intensity={0.5}/>
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={[512, 512]} castShadow />
          <Watch
            initial={{ scale: 0.002 }}
            animate={{ scale: 0.003 }}
            position={[0, 0, 0]}
            transition={{ type: 'spring', stiffness: 300, damping: 10, restDelta: 0.0000001 }}
          />
        <ContactShadows rotation-x={Math.PI / 2} position={[0, -1, 0]} opacity={0.75} width={1} height={2} blur={2.6} far={1.5} />
        <Environment preset="park" />
      </Suspense>
    </Canvas>
  )
}

function Watch(props: any) {

  const { scene, materials } = useGLTF('./assets/PINGUINO/PastelitoPinguinoNoCrema.glb')
  materials['aiChoco_mtl'].depthWrite = true;
  materials['aiPastelito_mtl'].depthWrite = true;
  
  const ref = useRef(null);
  const { name } = useStore();
  const matcapTexture = useTexture("./assets/firulitoMatcap.png");

  const gridConfig = {
    cellSize: 100,
    cellThickness: 0.5,
    cellColor: '#0253db',
    sectionSize: 100,
    sectionThickness: 0.5,
    sectionColor: '#0253db',
    fadeDistance: 15,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true
  }

  return (
    <>
    
    <motion.group {...props} position-y={-0.5} dispose={null} rotation={[0, -1, 0]}>
      <primitive object={scene} scale={[100, 100, 100]} rotation={[0, 0, 0]} >

      </primitive>
      <Center position={[0, 605, 0]}>
        <Text3D
          rotation={[-Math.PI/2, 0, 0]}
          scale={[20, 20, 20]}
          ref={ref}
          size={1.2 * (16 + (-name.length)-2)}
          font={"./fonts/Loverine.json"}
          curveSegments={12}
          bevelEnabled
          bevelSize={0.3}
          bevelThickness={1}
          height={1}
          lineHeight={0.9}
          letterSpacing={0.2}
        >
          <meshMatcapMaterial matcap={matcapTexture} />
          {`<${name}>`}
        </Text3D>
        </Center>
        <Grid position={[0, -160, 0]} args={[5000, 5000]} {...gridConfig} />
    </motion.group>
    </>
  )
}
