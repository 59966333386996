import React, { useRef, useState, useEffect, useMemo } from "react";
import { Canvas, Color, useFrame, Vector3 } from "@react-three/fiber";
import * as THREE from "three";
import { motion } from "framer-motion-3d";
import { usePenguinStore } from '../../PenguinStore';
import { Grid, OrbitControls, useTexture } from "@react-three/drei";
import { useNavigate } from "react-router-dom";
let audio = new Audio("./assets/hit.wav")

const MovingGrid: React.FC = () => {
  const gridRef = useRef<THREE.Mesh>(null!);

  // Movimiento perpetuo del grid
  useFrame((state) => {
    if (gridRef.current) {
      gridRef.current.position.x += -0.015; // Velocidad del movimiento hacia adelante
      if (gridRef.current.position.x < -100) {
        gridRef.current.position.x = 0; // Reinicio de la posición del grid
      }
    }
  });

  const gridConfig = {
    cellSize: 200,
    cellThickness: 0.5,
    cellColor: '#e6d2f9',
    sectionSize: 1.5,
    sectionThickness: 1.5,
    sectionColor: '#e6d2f9',
    fadeDistance: 15,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true
  }

  return (
   
    <Grid position={[0, -6, 0]} args={[50, 50]} {...gridConfig} ref={gridRef}/>
  );
};

type ParticleProps = {
  count: number;
  position: Vector3;
  color: Color;
};

const Explosion: React.FC<ParticleProps> = ({ count, position, color }) => {
  const particlesRef = useRef<THREE.InstancedMesh>(null);

  const particlesData = useMemo(() => {
    const data = [];
    for (let i = 0; i < count; i++) {
      const position = new THREE.Vector3(0, 0, 0);
      const velocity = new THREE.Vector3(
        (Math.random() - 0.5) * 3,
        (Math.random() - 0.5) * 3,
        (Math.random() - 0.5) * 3
      );
      data.push({ position, velocity, life: 0.5 });
    }
    return data;
  }, [count]);

  useFrame((_, delta) => {
    const mesh = particlesRef.current;
    if (!mesh) return;

    particlesData.forEach((particle, i) => {
      particle.position.addScaledVector(particle.velocity, delta);
      particle.life -= delta * 1;

      const matrix = new THREE.Matrix4();
      matrix.setPosition(particle.position);
      mesh.setMatrixAt(i, matrix);

      if (particle.life <= 0) {
        particle.position.set(0, 0, 0);
        particle.velocity.set(0, 0, 0);
      }

      mesh.instanceMatrix.needsUpdate = true;
    });
  });

  return (
    <instancedMesh ref={particlesRef} args={[undefined, undefined, count]} position={position}>
      <sphereGeometry args={[0.02, 16, 16]} />
      <meshStandardMaterial color={color} />
    </instancedMesh>
  );
};

const Obstacle: React.FC<{ 
  position: [number, number, number]; 
  color: string; 
  rotationSpeed: number;
  index: number;
  onCollision: () => void;
}> = ({
  position, color, rotationSpeed, index, onCollision
}) => {
  const { addObstacle, removeObstacle } = usePenguinStore();
  const obstacleRef = useRef<any>(null);
  const [destroyed, setDestroyed] = useState(false);
  const penguinRef = usePenguinStore((state: { penguinRef: any; }) => state.penguinRef);

  useEffect(() => {
    
    if (obstacleRef.current) {
      addObstacle(obstacleRef.current);
    }
    return () => {
      if (obstacleRef.current) {
        removeObstacle(obstacleRef.current);
      }
    };
  }, [obstacleRef, addObstacle, removeObstacle]);

  useFrame(() => {
    if (obstacleRef.current && penguinRef) {
      const obstaclePosition = new THREE.Vector3().copy(obstacleRef.current.position);
      const penguinPosition = new THREE.Vector3().copy(penguinRef.position);
      const distance = penguinPosition.distanceTo(obstaclePosition);

      if (distance < 0.85 && !destroyed) {
        setDestroyed(true);
        audio.play();
        onCollision();
      }
    }
  });

  return (
    <>
      {destroyed && <Explosion count={10} position={position} color={color}/>}
      <motion.group
        scale={destroyed ? [0, 0, 0] : [1, 1, 1]}
      >
          <motion.mesh
            ref={obstacleRef}
            position={[position[0] - 0.25, position[1], position[2]]}
            rotation={[0, 0, Math.PI / 2]}
            animate={{
              rotateX: [0, 15],
              y: [position[1]-0.5, position[1]+0.5, position[1]-0.5]
            }}
            transition={{
              rotateX:{
                duration: 10,
                repeat: Infinity,
              },
              y:{
                repeat: Infinity,
                duration: 5,
                ease: 'easeInOut',
                delay: index
              }
            }}
          >
            <coneGeometry args={[0.15, 0.25, 3]}/>
            <meshStandardMaterial color={color}/>
          </motion.mesh>
          <motion.mesh
            ref={obstacleRef}
            position={position}
            rotation={[0, 0, -Math.PI / 2]}
            animate={{
              rotateX: [0, 15],
              y: [position[1]-0.5, position[1]+0.5, position[1]-0.5]
            }}
            transition={{
              rotateX:{
                duration: 10,
                repeat: Infinity,
              },
              y:{
                repeat: Infinity,
                duration: 5,
                ease: 'easeInOut',
                delay: index
              }
            }}
          >
            <coneGeometry args={[0.15, 0.25, 3]} />
            <meshStandardMaterial color={color} />
          </motion.mesh>
      </motion.group>
    </>
  );
};

interface ObstacleType {
  position: [number, number, number];
  color: string;
  rotationSpeed: number;
}

const MovingObstacles: React.FC<{ onCollision: () => void; colors: string[]}> = ({ onCollision, colors }) => {
  const [obstacles, setObstacles] = useState<ObstacleType[]>([]);
  const obstacleRef = useRef<ObstacleType[]>([]);

  const [obstacles2, setObstacles2] = useState<ObstacleType[]>([]);
  const obstacleRef2 = useRef<ObstacleType[]>([]);

  const generateObstacleGroup = () => {
    const newObstacles: ObstacleType[] = [];
    const groupColor = colors[Math.floor(Math.random() * colors.length)];
    const baseX = 5;
    const baseY = Math.random() * 6 - 3;

    const offsets = [1, -1];
    const off = offsets[Math.floor(Math.random() * offsets.length)];

    for (let j = 0; j < 5; j++) {
      const xOffset = (j - 1.5) * 0.5;
      const yOffset = Math.sin(off * j - 0.5) * 0.3;
      const x = baseX + xOffset;
      const y = baseY + yOffset;
      const z = 0;

      newObstacles.push({
        position: [x, y, z] as [number, number, number],
        color: groupColor,
        rotationSpeed: Math.random() * 0.02
      });
    }

    setObstacles(prevObstacles => [...prevObstacles, ...newObstacles]);
    obstacleRef.current = [...obstacleRef.current, ...newObstacles];
  };

  const generateObstacleGroup2 = (offset:number = 0) => {
    const newObstacles: ObstacleType[] = [];
    const groupColor = colors[Math.floor(Math.random() * colors.length)];
    const baseX = 5 + offset;
    const baseY = Math.random() * 6 - 3;

    const offsets = [1, -1];
    const off = offsets[Math.floor(Math.random() * offsets.length)];

    for (let j = 0; j < 5; j++) {
      const xOffset = (j - 1.5) * 0.5;
      const yOffset = Math.sin(off * j - 0.5) * 0.3;
      const x = baseX + xOffset;
      const y = baseY + yOffset;
      const z = 0;

      newObstacles.push({
        position: [x, y, z] as [number, number, number],
        color: groupColor,
        rotationSpeed: Math.random() * 0.02
      });
    }

    setObstacles2(prevObstacles => [...prevObstacles, ...newObstacles]);
    obstacleRef2.current = [...obstacleRef.current, ...newObstacles];
  };

  useFrame(() => {
    setObstacles(prevObstacles =>
      prevObstacles
        .map(obstacle => {
          const newX = obstacle.position[0] - 0.010;
          return {
            ...obstacle,
            position: [newX, obstacle.position[1], obstacle.position[2]] as [number, number, number]
          };
        })
        .filter(obstacle => {
          const isWithinBounds = obstacle.position[0] > -5;
          return isWithinBounds;
        })
    );

    setObstacles2(prevObstacles =>
      prevObstacles
        .map(obstacle => {
          const newX = obstacle.position[0] - 0.010;
          return {
            ...obstacle,
            position: [newX, obstacle.position[1], obstacle.position[2]] as [number, number, number]
          };
        })
        .filter(obstacle => {
          const isWithinBounds = obstacle.position[0] > -5;
          return isWithinBounds;
        })
    );

    if (obstacleRef.current.length === 0) {
      generateObstacleGroup();
    }

    if (obstacleRef2.current.length === 0) {
      generateObstacleGroup2();
    }

  });

  useEffect(() => {
    obstacleRef.current = obstacles;
    obstacleRef2.current = obstacles2;
  }, [obstacles, obstacles2]);

  useEffect(() => {
    generateObstacleGroup2(5);
  }, []);

  return (
    <>
      {obstacles.map((obstacle, index) => (
        <Obstacle
          key={index}
          position={obstacle.position}
          color={obstacle.color}
          rotationSpeed={obstacle.rotationSpeed}
          index={index}
          onCollision={onCollision}
        />
      ))}

      {obstacles2.map((obstacle, index) => (
        <Obstacle
          key={index}
          position={obstacle.position}
          color={obstacle.color}
          rotationSpeed={obstacle.rotationSpeed}
          index={index}
          onCollision={onCollision}
        />
      ))}
    </>
  );
};

const Penguin: React.FC = () => {
  const penguinRef = useRef<THREE.Mesh>(null);
  const [mouseY, setMouseY] = useState(0);
  const setPenguinRef = usePenguinStore((state) => state.setPenguinRef);
  const texture = useTexture("./assets/pastelito.png")

  useEffect(() => {
    if (penguinRef.current) {
      setPenguinRef(penguinRef.current);
    }
  }, [penguinRef, setPenguinRef]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const normalizedY = -(event.clientY / window.innerHeight) * 2 + 1;
      setMouseY(normalizedY * 3.3);
    };
  
    const handleTouchMove = (event: TouchEvent) => {
      //event.preventDefault()
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        const normalizedY = -(touch.clientY / window.innerHeight) * 2 + 1;
        setMouseY(normalizedY * 3.3);
      }
    };
  
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);
  
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  useFrame(() => {
    if (penguinRef.current) {
      penguinRef.current.position.y = THREE.MathUtils.lerp(
        penguinRef.current.position.y,
        mouseY,
        0.1
      );
    }
  });

  return (
    <mesh ref={penguinRef} scale={[1.5, 1.5, 1.5]}>
      <planeGeometry args={[1, 1]}/>
      <meshStandardMaterial map={texture} transparent/>
    </mesh>
  );
};

const PenguinGame: React.FC<{
  soundtrack: HTMLAudioElement;
}> = ({soundtrack}) => {
  const [showTutorial, setShowTutorial] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState<number | null>(null);
  const [userAnswer, setUserAnswer] = useState("");
  const [gameEnded, setGameEnded] = useState(false);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [score, setScore] = useState(0); // Barra de carga
  const [questionIndex, setQuestionIndex] = useState(0);
  const { clearObstacles } = usePenguinStore();
  const navigate = useNavigate();


  const questions = [
    "¿Descubriste el enigma?",
    "¿Resolviste el enigma?",
    "¿Descubriste el último enigma?"
  ];

  const colors = [
    ['#f0d673', '#d4b565'],
    ['#d8a0c6', '#966474', '#ad7a8f'],
    ['#FFFFFF', '#623322'],
  ];

  const handleStartGame = () => {
    audio.play();
    setShowTutorial(false);
  };

  const handleAnswerSubmit = () => {
    if (userAnswer.trim()) {
      if (currentQuestion !== null) {
        setUserAnswers(prev => {
          const newAnswers = [...prev];
          newAnswers[questionIndex] = userAnswer.trim();
          return newAnswers;
        });

        if (questionIndex === questions.length - 1) {
          setGameEnded(true);
        } else {
          setQuestionIndex(questionIndex + 1);
          setCurrentQuestion(null);
          setUserAnswer("");
          setScore(0);
        }
      }
    }
  };

  const handleRestartGame = () => {
    setGameEnded(false);
    setShowTutorial(true);
    setCurrentQuestion(null);
    setUserAnswers([]);
    setUserAnswer("");
    setScore(0);
    clearObstacles();
    setQuestionIndex(0);
  };

  const handleCollision = () => {
    setScore(prevScore => {
      const newScore = prevScore + 8;
      if (newScore >= 100) {
        if (questionIndex < questions.length) {
          setCurrentQuestion(questionIndex);
        }
        clearObstacles();
      }
      return Math.min(newScore, 100);
    });
  };

  return (
    <div style={{ height: "100svh", width: "100vw", position: "relative", backgroundImage: "url(./assets/backNStars.png)", backgroundSize: "cover", backgroundPosition: "center", touchAction: 'none' }}>
      
      <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              color: "white",
            }}
            className="animate-fade w-10 lg:w-14 hover:scale-110 transition-all cursor-pointer z-50"
            onClick={()=> {soundtrack.pause() ; navigate('/')}}
          >
            <img src="./assets/home.png" alt="home"/>
      </div>

      <Canvas
        style={{ height: "100%", width: "100%" }}
      >
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} intensity={2} />
        {!showTutorial && currentQuestion === null && !gameEnded && <MovingObstacles onCollision={handleCollision} colors = {colors[questionIndex]}/>}
        {!showTutorial && currentQuestion === null && !gameEnded && <Penguin/>}
        <MovingGrid />
      </Canvas>
      <div
            style={{
              position: "absolute",
              top: "7.5%",
              left: "50%",
              transform: 'translateX(-50%)',
              color: "white",
            }}
            className="animate-fade font-bloomer text-md md:text-lg lg:text-2xl"
          >
            Nivel: {questionIndex+1}/{questions.length}
      </div>

      {<div className="hud animate-fade w-[20%] md:w-[15%] lg:w-[10%]" style={{ position: 'absolute', top: '5px', right: '10px', pointerEvents: 'none', userSelect: 'none'}}>
            <img src="./assets/logoenigma.png" alt="" style={{ width: '100%', pointerEvents: 'none' }} />
      </div>}

      {showTutorial && (
        <div style={{
          position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "20px", borderRadius: "10px", color: "white", textAlign: "center"
        }}
        className="animate-fade w-[70%] md:w-[50%] lg:w-[40%]"
        >
          <h1 style={{ color: "white", fontSize: '20px' }} className="font-bloomer">Bienvenido al Enigma</h1>
          <p style={{ color: "white", fontSize: '14px' }} className="font-bloomer">Scrolea para mover el pastelito, recolectar los objetos y descubrir el enigma</p>
          <br />
          <div className="relative mb-10">
            <img src="./assets/pastelito.png" alt="" className="w-24 mx-auto animate-move"/>
            <div className="w-20 absolute animate-move" style={{top: '80%', left: '50%' , transform: "translateX(-50%)"}}>
              <img src="./assets/index.png" alt="" className="w-100" style={{top: '50%', left: '50%' , transform: "translate(-50%, -50%)"}}/>
            </div>
          </div>
          <br />
          <button
                onClick={handleStartGame}
                style={{
                  padding: "10px 20px",
                  fontSize: "20px",
                  cursor: "pointer",
                  backgroundColor: "#8c6e90",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
                className="animate-wiggle font-bloomer"
              >
                Iniciar juego
          </button>
        </div>
      )}

      {currentQuestion !== null && !gameEnded && !showTutorial && (
        <div style={{
          position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -55%)",
          backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "20px", borderRadius: "10px", color: "white", textAlign: "center"
        }}
        className="animate-fade w-[70%] md:w-[50%] lg:w-[40%]"
        >
          <h2 className="font-bloomer">{questions[questionIndex]}</h2>
          <p className="font-bloomer"> ¿De qué sabor es?</p>
          <input
            type="text"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            style={{
              padding: "10px",
              width: "80%",
              marginTop: "10px",
              marginBottom: "10px",
              borderRadius: '10px',
              color: "#8c6e90",
            }}
          />
          <br />
          <button
                onClick={handleAnswerSubmit}
                style={{
                  padding: "10px 20px",
                  fontSize: "20px",
                  cursor: "pointer",
                  backgroundColor: "#8c6e90",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
                className="animate-wiggle font-bloomer"
              >
                Enviar
          </button>
        </div>
      )}

      {gameEnded && <>
          <div
            style={{
              position: "absolute",
              top: "35%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              width: '60%'
            }}
            className="animate-fade"
          >
            <img src="./assets/terminado.png" alt="" className="animate-wiggle mx-auto"/>
          </div>
          <div
            style={{
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              padding: "20px",
              borderRadius: "10px"
            }}
            className="animate-fade w-[70%] md:w-[50%] lg:w-[40%]"
          >
            <h1 style={{ color: "white", fontSize: '20px' }} className="font-bloomer">¡Gracias por participar!</h1>
            <p style={{ color: "white", fontSize: '14px' }} className="font-bloomer">Quédate pendiente para resolver el próximo Enigma.</p>
            <button
              onClick={handleRestartGame}
              style={{
                padding: "10px 20px",
                fontSize: "20px",
                cursor: "pointer",
                backgroundColor: "#8c6e90",
                color: "white",
                border: "none",
                borderRadius: "5px",
                marginTop: "10px",
              }}
              className="animate-wiggle font-bloomer"
            >
              Reiniciar
            </button>
          </div>
        </>
      }

      <div style={{
        position: "absolute", top: "10%", left: "50%", backgroundImage: "url('./assets/barra.png')", backgroundSize: 'contain', backgroundPosition: '0%', backgroundRepeat: 'no-repeat',
        width: "250px", height: "30px", borderRadius: "10px", overflow: "hidden", transform: "translateX(-50%)"
      }}>
        <div style={{
          backgroundImage: "url('./assets/barraFill.png')", height: "100%", maxWidth: '95%', width: `${score}%`, transition: "width 0.3s", backgroundSize: 'cover', backgroundRepeat: 'repeat-x'
        }} />
      </div>
    </div>
  );
};

export default PenguinGame;
