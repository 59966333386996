import ReactDOM from 'react-dom/client';
import './index.css';
import './scripts.js';
import reportWebVitals from './reportWebVitals';
import AppRouter from './router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(

  <>
    <AppRouter />
  </>
);

reportWebVitals();
