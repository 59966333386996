import * as THREE from "three";
import create from 'zustand';

interface ObstacleStore {
  obstacles: THREE.Object3D[];
  penguinRef: THREE.Object3D | null;
  addObstacle: (obstacle: THREE.Object3D) => void;
  removeObstacle: (obstacle: THREE.Object3D) => void;
  clearObstacles: () => void;
  setPenguinRef: (ref: THREE.Object3D) => void;
}

export const usePenguinStore = create<ObstacleStore>((set) => ({
  obstacles: [],
  penguinRef: null,
  addObstacle: (obstacle) =>
    set((state) => ({ obstacles: [...state.obstacles, obstacle] })),
  removeObstacle: (obstacle) =>
    set((state) => ({
      obstacles: state.obstacles.filter((o) => o !== obstacle),
    })),
  clearObstacles: () => set({ obstacles: [] }),
  setPenguinRef: (ref) =>
    set({ penguinRef: ref }),
}));