import { create } from 'zustand';

interface sticker {
    id?: number;
    size?: string;
    x?: number;
    y?: number;
    rotation?: number;
    scale?: number;
    url?: string;
    z: number;
    selected? :boolean;
}

interface text {
    text?: string;
    width?: string;
    height?: string;
    x?: number;
    y?: number;
    rotation?: number;
    scale?: number;
    color?: string;
    font?: string;
    fontStyle?: string;
}

interface user {
    name?: string;
    day?: number;
    month?: number;
    year?: number;
    email?: string;
    phone?: string;
}

interface AppState {
  name: string;
  text: text;
  background: string;
  stickers: sticker[];
  videos: HTMLVideoElement[];
  image: string;
  user: user;
  setVideos: (videos: HTMLVideoElement[]) => void;
  setName: (name: string) => void;
  setText: (name: text) => void;
  setUser: (user: user) => void;
  setImage: (url: string) => void;
  setBackground: (newBackground: string) => void;
  addSticker: (sticker: sticker) => void;
  addPastelito: (sticker: sticker) => void;
  updateSticker: (sticker: sticker) => void;
  deleteSticker: (sticker: sticker) => void;
  selectSticker: (sticker: sticker) => void;
}

export const useStore = create<AppState>((set) => ({
    name: '',
    text: {
        text: '',
        width: '150px',
        height: '50px',
        rotation: 0,
        scale: 1,
        x: 0,
        y: 0,
        font: 'font-anton',
        fontStyle: 'normal',
        color: '#83aee6'
    },
    videos: [],
    user: {
        name: '',
        day: -1,
        month: -1,
        year: -1,
        email: '',
        phone: ''

    },
    background: './assets/backs/Fondo1.png',
    stickers: [],
    image: '',
    setVideos: (videos: HTMLVideoElement[]) => set((state) => ({ videos })),
    setName: (name: string) => set((state) => ({ name: name })),
    setText: (text: text) => set((state) => ({ text: text })),
    setUser: (user: user) => set((state) => ({ user })),
    setImage: (url: string) => set((state) => ({ image: url })),
    setBackground: (newBackground: string) => set((state) => ({ background: newBackground })),
    addSticker: (sticker: any) => set((state) => ({
        stickers: add(state, sticker)
    })),
    addPastelito: (sticker: any) => set((state) => ({
        stickers: [...state.stickers, {...sticker}]
    })),
    updateSticker: (sticker: sticker) => set((state) => ({
        stickers: update(state, sticker) 
    })),
    selectSticker: (sticker: sticker) => set((state) => ({
        stickers: select(state, sticker) 
    })),
    deleteSticker: (sticker: sticker) => set((state) => ({
        stickers: delete_(state, sticker) 
    }))
}));

function add(state: AppState, sticker: sticker){
    if(state.stickers.length<15){
        return [...state.stickers, {...sticker, id: state.stickers.length+1, size: '100', x: 0, y: 0, scale: 1, rotation: 0}]
    }
    else  
        return [...state.stickers]
    
}

function update(state: AppState, sticker: sticker){
    const nextStickers = state.stickers.map((c, i) => {
        if (c.id === sticker.id) {
            const maxZ = Math.max(...state.stickers.map(x => x.z))
            sticker.z = maxZ + 1
          // Increment the clicked counter
          return sticker;
        } else {
          // The rest haven't changed
          return c;
        }
      });
      return nextStickers
}

function select(state: AppState, sticker: sticker){
    const nextStickers = state.stickers.map((c, i) => {
        if (c.id === sticker.id) {
            sticker.selected = true
          // Increment the clicked counter
          return sticker;
        } else {
          // The rest haven't changed
          c.selected = false
          return c;
        }
      });
      return nextStickers
}

function delete_(state: AppState, sticker: sticker){
    const nextStickers = state.stickers.filter(x => x.id !== sticker.id)
      return nextStickers
}