import React, { useState, useRef, useEffect } from 'react';
import Moveable from 'react-moveable';
import { useStore } from '../../store';
import { AutoTextSize } from 'auto-text-size';

interface state {
  id?: number;
  size?: string;
  x?: number;
  y?: number;
  rotation?: number;
  scale?: number;
  url?: string;
  deleteRef?: any;
  z: number;
  hide? :boolean;
  selected? :boolean;
}

const Sticker: React.FC<state> = (props: state) => {
  const [state, setState] = useState<state>({...props})
    const { updateSticker, name, deleteSticker, selectSticker} = useStore();
    const [isCollisioning, setIsCollisioning] = useState(false);
    const collisionRef = useRef<HTMLImageElement>(null); 
    const imgRef = useRef<HTMLDivElement>(null);

  useEffect(() =>{

    setState({ ...props })

  }, [])

  type Rect = {
    left: number;
    top: number;
    right: number;
    bottom: number;
};

const getBoundingClientRect = (element: HTMLElement): Rect => {
    const rect = element.getBoundingClientRect();
    return {
      left: rect.left,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom
    };
};

const isColliding = (rect1: Rect, rect2: Rect): boolean => {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
};

const checkCollision = () => {
  
  if(props.id === 99) return
  if (collisionRef.current && props.deleteRef.current) {
    const element1 = collisionRef.current as HTMLElement;
    const rect1 = getBoundingClientRect(element1);
    const rect2 = getBoundingClientRect(props.deleteRef.current);
    if(isColliding(rect1, rect2)){
      props.deleteRef.current.className = 'absolute w-16 h-12 left-1/2 -translate-x-1/2 bottom-2 transition-all hue-rotate-90'
      setIsCollisioning(true)
    }else{
      props.deleteRef.current.className = 'absolute w-12 h-18 left-1/2 -translate-x-1/2 bottom-2 transition-all hue-rotate-0'
      setIsCollisioning(false)
    }
    
  }
};

const deleteElement = () => {

  props.deleteRef.current.className = 'absolute w-12 h-18 left-1/2 -translate-x-1/2 bottom-2 transition-all hue-rotate-0'
  if(isCollisioning)
    deleteSticker(props)
  
}

  return (
    <div>
      <div
        ref={imgRef}
        style={{
            position: 'absolute',
            left: state.x,
            top: state.y,
            width: +(state.size || 0),
            height: +(state.size || 0),
            transform: `rotate(${state.rotation}deg) scale(${state.scale})`,
            backgroundImage: `url('${state.url}')`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: props.z,
            cursor: 'grab',
            pointerEvents: props.hide ? 'none': 'all'
        }}
      >
        {<div ref={collisionRef} className="w-1/4 h-1/4 absolute bottom-1/2 left-1/2 -translate-x-1/2 translate-y-1/2"/>}
        {props.id === 99 && <div className={`absolute -translate-y-1/2 left-1/2 -translate-x-[51%] w-[90%] top-1/2`}>
            <AutoTextSize
                className={`font-loverine text-white text-center mx-auto font-extrabold`}
                minFontSizePx={12}
            >
                {`<${name}>`}
            </AutoTextSize>
        </div>}
      </div>
      <Moveable
        target={imgRef.current}
        draggable={!props.hide}
        scalable={!props.hide}
        rotatable={!props.hide && props.selected}
        origin={false}
        renderDirections={props.selected? ["nw", "ne", "sw", "se"]: []}
        hideDefaultLines={props.hide || !props.selected}
        onRotate={e=>{
          setState({...state, rotation: e.rotation})
        }}
        onDrag={e=>{
          setState({...state, x: e.left, y: e.top})
          checkCollision()
          selectSticker(state)
          
        }}
        onClick={()=>selectSticker(state)}
        onScale={e=>{
          setState({...state, scale: e.scale[0]})
        }}
        onDragEnd={e=>{
          updateSticker({...state})
          deleteElement()
        }}
        onScaleEnd={e=>{
          updateSticker({...state})
        }}
        onRotateEnd={e=>{
          updateSticker({...state})
        }}
        keepRatio={true}
      />
    </div>
  );
};

export default Sticker;