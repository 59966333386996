import React from 'react';

interface PhoneMockupProps {
  generatedImageSrc: string;
  width: string;
}

const PhoneMockup2: React.FC<PhoneMockupProps> = ({ generatedImageSrc, width }) => {

  return (
    <div className="mockup-container mx-auto"
      style={{
        backgroundImage: `url(${generatedImageSrc})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95% 95%',
        backgroundPosition: 'center',
        borderRadius: '15%',
        width: width
      }}
    >
      <img 
        src='./assets/celular2.png'
        alt="Mockup del dispositivo" 
        className="mockup-image overflow-hidden"
      />
    </div>
  );
};

export default PhoneMockup2;