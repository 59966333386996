import { useEffect, useRef } from "react";
import Vara from "vara";

export function VaraText({ text, delay, id, fontsize, parentWidth }: { text: string, delay: number, id: string, fontsize?: number, parentWidth: number }) {

    const mainCont = useRef<HTMLDivElement>(document.createElement("div"))
    let spacing = -25
    
    useEffect(() => {
      console.log(mainCont.current.clientWidth);
      

      if(!fontsize){
        fontsize = 70;
        if(text.length == 7) {//fontsize = 140; spacing = -25 
          spacing = -0.032 * mainCont.current.clientWidth; fontsize = (mainCont.current.clientWidth / (text.length + 1.7)) * 2
          if(text.includes('m') || text.includes('w')) fontsize = (mainCont.current.clientWidth / (text.length + 2.5)) * 2
        }else if(text.length == 6) {//fontsize = 140; spacing = -25 
          spacing = -0.036 * mainCont.current.clientWidth; fontsize = (mainCont.current.clientWidth / (text.length + 1.8)) * 2
          if(text.includes('m') || text.includes('w')) fontsize = (mainCont.current.clientWidth / (text.length + 2.8)) * 2
        }
        else if(text.length == 5) {
          //fontsize = 155; spacing = -30
          spacing = -0.045 * mainCont.current.clientWidth; fontsize = (mainCont.current.clientWidth / (text.length + 1.9)) * 2
          if(text.includes('m') || text.includes('w')) fontsize = (mainCont.current.clientWidth / (text.length + 2.8)) * 2
        }
        else if(text.length == 4) {
          //fontsize = 180; spacing = -35
          spacing = -0.053 * mainCont.current.clientWidth; fontsize = (mainCont.current.clientWidth / (text.length + 2)) * 2
          if(text.includes('m') || text.includes('w')) fontsize = (mainCont.current.clientWidth / (text.length + 2.7)) * 2
        }
        else if(text.length == 3) {
          //fontsize = 200; spacing = -40
          spacing = -0.061 * mainCont.current.clientWidth; fontsize = (mainCont.current.clientWidth / (text.length + 2.2)) * 2
          if(text.includes('m') || text.includes('w')) fontsize = (mainCont.current.clientWidth / (text.length + 2.9)) * 2
        }
        else if(text.length == 2) {
          //fontsize = 220; spacing = -45
          spacing = -0.068 * mainCont.current.clientWidth; fontsize = (mainCont.current.clientWidth / (text.length + 2.5)) * 2
          if(text.includes('m') || text.includes('w')) fontsize = (mainCont.current.clientWidth / (text.length + 3)) * 2
        }
        else if(text.length == 1) {
          //fontsize = 240; spacing = -50
          spacing = -0.076 * mainCont.current.clientWidth; fontsize = (mainCont.current.clientWidth / (text.length + 2.5)) * 2
          if(text.includes('m') || text.includes('w')) fontsize = (mainCont.current.clientWidth / (text.length + 3)) * 2
        }
        
      }
      var vara = new Vara(
        `#${id}`,
        "./fonts/loverineVara.json",
        [
          {
            text: `<${text}>`,
            fontSize: fontsize,
            strokeWidth: 1.2,
            textAlign:"center",
            color:"white",
            letterSpacing: spacing,
            delay: delay,
            duration:2500,
            y: 10
          }
        ]
      );
    }, []);
   
    return <div id={id} className="z-[1] w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/3 text-center" ref={mainCont}></div>;
}