import { Link, useNavigate } from "react-router-dom";
import WavyText from "./helpers/WavyText";
import { AnimatedText } from "./helpers/AnimatedText";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useStore } from "../store";
import Loading from "./helpers/Loading";

export default function Step3(){

    const navigate = useNavigate();
    const { name } = useStore();
    const mainCont = useRef<HTMLDivElement>(document.createElement("div"))
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(()=>{
        window.scrollTo(0, 0)
        if(!name){
            navigate("/celebracion");
        }
    },[])
    
    return (
        <>

        {!isPageLoaded && <Loading/>}

            <video preload="metadata" autoPlay muted loop className="hidden lg:block myVideo pointer-events-none" style={{minHeight: '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back4R.mp4' type="video/mp4" />
            </video>
            <video preload="metadata" autoPlay muted loop className="block lg:hidden myVideo pointer-events-none" style={{minHeight: '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back4v2R.mp4' type="video/mp4" />
            </video>
            <div className="flex flex-row min-h-svh md:items-center justify-center pt-12 md:pt-0 overflow-hidden relative" ref={mainCont}>
                <div className="basis-0 lg:basis-1/2">
                   
                </div>
                <div className="basis-full lg:basis-1/3">
                    <div className="w-full text-center pt-20">
                        <div className="">
                            <WavyText x={-50} y={100} z={120} className="text-[#ebff00] font-ttBold text-3xl md:text-6xl lg:text-8xl inline-block text-center uppercase scale-y-125 drop-shadow justify-center" text="Haz una tarjeta" replay={true} />
                            <WavyText x={50} y={-100} z={-180} className="text-[#ebff00] font-ttBold text-3xl md:text-6xl lg:text-8xl inline-block text-center uppercase scale-y-125 drop-shadow leading-[2.5rem] md:leading-[5rem] lg:leading-[8rem] justify-center" text="aesthetic" replay={true} />
                        </div>
                        <div className="flex flex-row mt-2  w-full lg:w-[55%] mx-auto">
                            <AnimatedText
                                    el="p"
                                    text={[
                                        "Ninguna sorpresa está completa sin una tarjeta de celebración."
                                    ]}
                                    stagger={0.3}
                                    delay={0.5}
                                    className="text-white text-center font-light basis-5/6 text-sm md:text-md lg:text-xl mx-auto"
                            />
                        </div> 

                        <div className="flex flex-row mt-8 mb-16 space-x-8 md:space-x-12 justify-center">
                            <Link to="/celebracion-2">
                                <motion.button 
                                    type="submit"
                                    className="cursor-pointer bg-[#ffffff] hover:opacity-100 text-[#fa0100] text-md md:text-2xl font-bloomer py-3 md:py-5 px-6 md:px-8 rounded-full uppercase basis-1/4 opacity-85 transition-all"
                                    animate={{ scale: [0.8, 1.4, 0.8]}}
                                    transition={{
                                    type: "linear",
                                    duration: 3,
                                    repeat: Infinity
                                    }}
                                >
                                    ANTERIOR
                                </motion.button>
                            </Link>
                            <Link to="/celebracion-4">
                                <motion.button 
                                    type="submit"
                                    className="cursor-pointer bg-[#ffffff] hover:opacity-100 text-[#fa0100] text-md md:text-2xl font-bloomer py-3 md:py-5 px-6 md:px-8 rounded-full uppercase basis-1/4 opacity-85 transition-all"
                                    animate={{ scale: [1.4, 0.8, 1.4]}}
                                    transition={{
                                    type: "linear",
                                    duration: 3,
                                    repeat: Infinity
                                    }}
                                >
                                    SIGUIENTE
                                </motion.button>
                            </Link>
                        </div> 
                    </div>
                </div>
                <div className="basis-0 lg:basis-1/6"></div>
                <motion.img
                    animate={{ scale: [1.05, 1, 1.05]}}
                    transition={{
                    type: "linear",
                    duration: 6,
                    repeat: Infinity
                    }}
                    src="./assets/chica.png" 
                    className="absolute w-2/3 md:w-1/3 mx-auto left-0 bottom-0 "
                    style={{zIndex: '-1'}}
                />
            </div>
              
            
        </>
    )
}