import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
    motion,
    useAnimate
  } from "framer-motion";
import { useStore } from "../store";
import { AutoTextSize } from "auto-text-size";
import Loading from "./helpers/Loading";
import { VaraText } from "./helpers/VaraText";
import SvgLetter from "./helpers/SvgLetter";


export default function Animation(){

  const { name, setName } = useStore();
  const mainCont = useRef<HTMLDivElement>(document.createElement("div"))
  const navigate = useNavigate();
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isMobile , setIsMobile ] = useState<boolean>(window.innerWidth <= 768);

  const [scope, animate] = useAnimate()
  const [scope7, animate7] = useAnimate()

  async function sequence() {
    await animate([
        [scope.current, { left: '50vw' }, {duration: 0.9, delay: 0.3}],
        [scope.current, { left: '140vw' }, {duration: 0.9, delay: 6}]
    ])
    navigate("/celebracion-3");
  }


  async function sequence2() {
    await animate7([
        [scope7.current, { right: '33vw' }, {duration: 1, delay: 0}],
        [scope7.current, { right: `${isMobile ? '-30vw': '5vw'}`, scale:[1.1, 1, 1.1, 1, 1.1, 1, 1.1, 1] }, {duration: 4, delay: 0.2}],
        [scope7.current, { right: `${isMobile ? '-50vw': '-20vw'}` }, {duration: 2, delay: 0.3}],
    ])

    

  }

  function started(){
    sequence()
    sequence2()
    setIsPageLoaded(true)
  }

  useEffect(() =>{
    window.scrollTo(0, 0)
    if(!name){
      navigate("/celebracion");
      return
    }
      
      
  }, [])

    return (
        <>

            {!isPageLoaded && <Loading/>}
            <video preload="metadata" autoPlay muted loop className="myVideo" style={{minHeight: mainCont.current.scrollHeight || '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => started()}>
              <source src='./assets/animation.mp4' type="video/mp4" />
            </video>
            <div className="w-screen h-svh absolute overflow-hidden" ref={mainCont}>

              <motion.div className="absolute size-[40svh] md:size-[55svh] top-[55%] md:top-[59%] -translate-x-1/2 -translate-y-1/2 overflow-hidden"
                initial={{ left: '-40vw' }}
                ref={scope}
              >
                <motion.img src="./assets/pastelSR.png" alt="pinguino"/>

                  <motion.div className="absolute top-1/2 w-[100%] -translate-y-[60%] h-1/3 left-1/2 -translate-x-1/2">

                    <motion.div className={`absolute left-1/2 -translate-x-[50%] w-[100%] top-[35%]`}
                      initial={{opacity: 1}}
                      animate={{opacity: 1}}
                      transition={{
                        duration: 2,
                        delay: 4
                      }}
                    >
                      {/*<AutoTextSize
                          className={`font-loverine text-white text-center mx-auto font-extrabold`}
                          minFontSizePx={12}
                      >
                          {`<${name}>`}
                      </AutoTextSize>*/}
                      <VaraText delay={1500} text={name} id={"ddd"} parentWidth={100}/>

                    </motion.div>
                    
                  </motion.div>
                  
                  
                </motion.div>

              <motion.img
                src="./assets/maquina.png" alt="pinguino" className="absolute w-[100vw] md:w-[80vw] lg:w-[65vw] h-[50svh] md:h-[65svh] lg:h-[90svh] top-[0svh] md:-top-[15svh] lg:-top-[35svh]"
                initial={{ right: '5vw' }}
                animate={{}}
                transition={{ repeat: Infinity, duration: 50}}
                ref={scope7}
              />              

            </div>
        </>
    )
}