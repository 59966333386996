import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { AnimatedText } from "./helpers/AnimatedText";
import WavyText from "./helpers/WavyText";
import { useStore } from "../store";
import { useRef, useState } from "react";
import Loading from "./helpers/Loading";

export default function Step2(){

    const { name, setName } = useStore();
    const mainCont = useRef<HTMLDivElement>(document.createElement("div"))
    const [firstClick, setFirstClick] = useState(false)
    const navigate = useNavigate()
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    function onWrite(text: string){
        if(text.length<8){
            let res = /^[a-z ]*$/.test(text.toLocaleLowerCase());
            if(res)
                setName(text.toLocaleLowerCase())
        }
    }

    function handleSubmit(){
        setFirstClick(true)
        if(name){
            navigate('/celebracion-animation')
        }
    }

    return (
        <>
            {!isPageLoaded && <Loading/>}

            <video preload="metadata" autoPlay muted loop className="hidden lg:block myVideo pointer-events-none" style={{minHeight: mainCont.current.scrollHeight || '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back3R.mp4' type="video/mp4" />
            </video>
            <video preload="metadata" autoPlay muted loop className="block lg:hidden myVideo pointer-events-none" style={{minHeight: mainCont.current.scrollHeight || '100svh'}} playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}>
              <source src='./assets/back3v2R.mp4' type="video/mp4" />
            </video>
            <div className="flex flex-row min-h-svh md:items-center justify-center pb-20"  ref={mainCont}>
                <div className="basis-0 lg:basis-1/6"></div>
                <div className="basis-full lg:basis-1/2 xl:basis-1/3">
                    <form className="w-full mt-8 p-10 text-center pt-12"
                        onSubmit={e => e.preventDefault()}
                    >
                        <div className="">
                            <WavyText x={-50} y={-100} z={-120} className="text-[#ebff00] font-ttBold text-3xl md:text-6xl xl:text-8xl inline-block text-center uppercase scale-y-125 drop-shadow justify-center" text="Haz tu Pingüino" replay={true} />
                            <WavyText x={50} y={100} z={-120} className="text-[#ebff00] font-ttBold text-3xl md:text-6xl xl:text-8xl inline-block text-center uppercase scale-y-125 drop-shadow leading-[2.5rem] md:leading-[6rem] xl:leading-[9rem] justify-center" text="personalizado" replay={true} />
                        </div>
                        <div className="space-y-4 mt-5">
                            {/*<label htmlFor="name" className="block text-[#fff] uppercase font-extrabold text-md">
                                ingresa el nombre que estará en tu pingüino
                            </label>*/}
                            <motion.textarea 
                                id="name"
                                name="name"
                                placeholder="Pon el nombre de tu amix."
                                className={`bg-[#e3d7f8] px-5 py-14 xl:py-20 overflow-hidden w-full rounded-[40px] ${!name && firstClick ? 'outline-dashed outline-2 -outline-offset-8 outline-red-700': 'outline-none'} text-center text-sm md:text-xl text-[#9b84c4] placeholder-[#9b84c490] block mx-auto leading-8 uppercase opacity-90 font-bloomer`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 4, delay: 0.6}}
                                rows={name ? 1 : 1}
                                value={name}
                                onInput={(e:any) => onWrite(e.target['value'])}
                            />
                            <AnimatedText
                                el="p"
                                text={[
                                    "Usa hasta 7 caracteres. Evita usar */_-¿¡ etc. "
                                ]}
                                stagger={0.3}
                                delay={1.5}
                                className="text-[#fff] text-sm md:text-md xl:text-xl font-extralight w-full md:w-5/6 mx-auto opacity-70"
                                />
                            
                        </div>

                        <div className="text-center mx-auto w-full mt-4 z-50">
                            <motion.button 
                                type="submit"
                                className="cursor-pointer bg-[#ebff00] hover:bg-[#eaff00e5] text-[#0353da] text-md md:text-3xl font-bloomer py-3 md:py-4 px-10 md:px-16 rounded-full uppercase"
                                animate={{ scale: [1, 1.2, 1]}}
                                transition={{
                                  type: "linear",
                                  duration: 3,
                                  repeat: Infinity
                                }}
                                onClick={()=>handleSubmit()}
                            >
                                ¡LISTO!
                            </motion.button>
                        </div>

                    </form> 
                    <motion.img
                        animate={{ scale: [1.05, 1, 1.05]}}
                        transition={{
                        type: "linear",
                        duration: 3,
                        repeat: Infinity
                        }}
                        src="./assets/pastelVelaG.png" 
                        className="block lg:hidden w-1/2 mx-auto"
                    />
                </div>
                <div className="basis-1 lg:basis-1/2">
                    <motion.img
                        animate={{ scale: [1.05, 1, 1.05]}}
                        transition={{
                        type: "linear",
                        duration: 3,
                        repeat: Infinity
                        }}
                        src="./assets/pastelVelaG.png" />
                </div>
                  
            </div>
        </>
    )
}