import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface Offset {
  x: number;
  y: number;
}

interface MouseTrackerProps {
  children: React.ReactNode;
  offset?: Offset;
}

const MouseTracker: React.FC<MouseTrackerProps> = ({ children, offset = { x: 0, y: 0 } }) => {
  const element = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handler(e: MouseEvent | TouchEvent) {
      let x: number, y: number;
      if (e instanceof MouseEvent) {
        x = e.clientX + offset.x;
        y = e.clientY + offset.y;
      } else if (e instanceof TouchEvent && e.touches.length > 0) {
        x = e.touches[0].clientX + offset.x;
        y = e.touches[0].clientY + offset.y;
      } else {
        return;
      }

      if (element.current) {
        element.current.style.transform = `translate(${x}px, ${y}px)`;
        element.current.style.visibility = 'visible';
      }
    }

    document.addEventListener('mousemove', handler);
    document.addEventListener('touchmove', handler);

    return () => {
      document.removeEventListener('mousemove', handler);
      document.removeEventListener('touchmove', handler);
    };
  }, [offset]);

  return createPortal(
    <div className='mouse-tracker fixed pointer-events-none top-0 left-0' ref={element}>
      {children}
    </div>,
    document.body
  );
};

export default MouseTracker;
