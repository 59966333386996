import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MouseTracker from './helpers/MouseTracker';
import Loading from './helpers/Loading';

const SelectionScreen: React.FC = () => {
  const [hoveredSection, setHoveredSection] = useState<'left' | 'right' | null>(null);
  const leftVideoRef = useRef<HTMLVideoElement>(null);
  const rightVideoRef = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();

  const [found, setFound] = useState(false);
  const [checkedPN, setCheckedPN] = useState(false);
  const [firstClick, setFirstClick] = useState(false)

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  function handleSubmit(){
    setFirstClick(true)
    if(checkedPN){
      setFound(true)
    }
  }

  const handleMouseEnter = (section: 'left' | 'right') => {
    //if(!found) return
    setHoveredSection(section);
    if (section === 'left' && leftVideoRef.current) {
      leftVideoRef.current.play();
    } else if (section === 'right' && rightVideoRef.current) {
      rightVideoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    //if(!found) return
    setHoveredSection(null);
    if (leftVideoRef.current) {
      leftVideoRef.current.pause();
      leftVideoRef.current.currentTime = 0; // Reiniciar el video
    }
    if (rightVideoRef.current) {
      rightVideoRef.current.pause();
      rightVideoRef.current.currentTime = 0; // Reiniciar el video
    }
  };

  return (
    <>

        <header className="-mb-[3.15rem] md:-mb-[3.6rem] lg:-mb-[4.6rem] xl:-mb-[5.55rem] top-0 left-0 right-0 px-2 absolute z-50">
            <div className="mx-auto container pt-1">
                <div className="flex justify-between items-center mx-3 mt-2">
                    <div>
                        <Link to="/"><img className="w-20 md:w-24 lg:w-32 xl:w-40" src="/assets/marinela-logo.png" alt="logotipo"/></Link>
                    </div>
                    <div>
                        <Link to="/"><img className="w-16 md:w-20 lg:w-24 xl:w-32" src="/assets/pinguinos.png" alt="logotipo"/></Link>
                    </div>
                    {/*<div>
                        <img className="w-10 md:w-12 lg:w-16" src="/assets/menu.png" alt="logotipo"/>
                    </div>*/}
                </div> 
            </div>
        </header>

        {found && <>
        <div style={{ height: "100svh", width: "100vw", position: "absolute", backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPositionX: 'center', opacity: 1 }} className='animate-fade bg-[#0253db]'></div>
            <div style={{ width: '100vw', height: '100svh'}} className='transition-all duration-1000'>
                <div
                    style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "20px",
                    borderRadius: "10px"
                    }}
                    className="animate-fade w-[70%] md:w-[50%] lg:w-[40%]"
                >
                    <div className="relative" >

                    <ul className={`justify-center w-full md:w-1/2 mx-auto`}>
                        <li>
                            <img src="./assets/info.png" alt="" className="w-16 mx-auto animate-ping2"/>
                            <input type="radio" id="hosting-big" name="hosting" value="hosting-big" className="hidden peer" onChange={() => setCheckedPN(true)} checked={checkedPN} />
                            <label htmlFor='hosting-big' className={`inline-flex p-1 items-center justify-center w-full my-2 text-gray-50 rounded-xl peer-checked:border-blue-600 peer-checked:text-blue-60 ${!checkedPN && firstClick ? 'outline-dashed outline-2 -outline-offset-1 outline-red-700 bg-[#ff00006e]': 'outline-none'}`}>
                                <div className="block">
                                    <div className="w-full text-md md:text-md lg:text-2xl font-bloomer">Esta experiencia esta diseñada para personas mayores de 18 años. </div>
                                    <div className="w-full text-xs md:text-md lg:text-lg font-bloomer">Para continuar, marca la casilla indicando que eres mayor de edad. </div>
                                    <svg className={`mt-2 mx-auto w-10 h-10 rtl:rotate-180 hover:text-gray-300 hover:scale-110 transition-all cursor-pointer`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeWidth="1.5" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"/>
                                        {checkedPN && <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>}
                                    </svg>
                                </div>
                                
                            </label>
                        </li>
                    </ul>

                    </div>
                    <button
                    onClick={() => handleSubmit()}
                    style={{
                        padding: "10px 20px",
                        fontSize: "20px",
                        cursor: "pointer",
                        backgroundColor: "#ebff00",
                        color: "#0353da",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "10px",
                        fontFamily: 'Bloomer'
                    }}
                    className="animate-wiggle"
                    >
                    ENTRAR
                    </button>
                </div>
            </div>
        </>}

        {!found &&<>{!isPageLoaded && <Loading/>}<div className="flex h-svh flex-col md:flex-row absolute top-0">
            <div
                className={`relative flex-1 flex items-center justify-center transition-all duration-300 ${
                hoveredSection === 'left' ? 'flex-[2]' : ''
                } ${hoveredSection === 'right' ? 'brightness-50' : ''}`}
                onMouseEnter={() => handleMouseEnter('left')}
                onMouseLeave={handleMouseLeave}
                onTouchMove={() => handleMouseEnter('left')}
                onTouchCancel={handleMouseLeave}
                onClick={() => navigate('/celebracion')}
            >
                <video
                ref={leftVideoRef}
                src="./assets/screen1.mp4"
                className="h-full w-full object-cover transition-all duration-300 cursor-pointer"
                muted
                loop
                autoPlay
                playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}
                />
                {hoveredSection === 'left' && <MouseTracker offset={{ x: 20, y: 20}}>
                    <div 
                        style={{
                            padding: "10px 20px",
                            fontSize: "20px",
                            cursor: "pointer",
                            backgroundColor: "#ebff00",
                            color: "#0353da",
                            border: "none",
                            borderRadius: "5px",
                            marginTop: "10px",
                            fontFamily: 'Bloomer'
                        }}
                        className="animate-wiggle"
                    >
                        IR A CELEBRACIÓN
                    </div>
                </MouseTracker>}
            </div>
            <div
                className={`relative flex-1 flex items-center justify-center transition-all duration-300 ${
                hoveredSection === 'right' ? 'flex-[2]' : ''
                } ${hoveredSection === 'left' ? 'brightness-50' : ''}`}
                onMouseEnter={() => handleMouseEnter('right')}
                onMouseLeave={handleMouseLeave}
                onTouchMove={() => handleMouseEnter('right')}
                onTouchCancel={handleMouseLeave}
                onClick={() => navigate('/enigma')}
            >
                <video
                ref={rightVideoRef}
                src="./assets/screen2.mp4"
                className="h-full w-full object-cover transition-all duration-300 cursor-pointer"
                muted
                loop
                autoPlay
                playsInline webkit-playsinline="true" onCanPlayThrough={() => setIsPageLoaded(true)}
                />
                {hoveredSection === 'right' && <MouseTracker offset={{ x: 20, y: 20}}>
                    <div 
                        style={{
                            padding: "10px 20px",
                            fontSize: "20px",
                            cursor: "pointer",
                            backgroundColor: "#8c6e90",
                            color: "WHITE",
                            border: "none",
                            borderRadius: "5px",
                            marginTop: "10px",
                            fontFamily: 'Bloomer'
                        }}
                        className="animate-wiggle"
                    >
                        IR A ENIGMA
                    </div>
                </MouseTracker>}
            </div>
        </div></>}

        <footer className="w-full z-50 px-2 absolute bottom-0">

            <div className="flex justify-center items-center mx-3 text-center">
                <div>
                    <p
                        className="text-[#ffffffad] transition-all text-center text-xs md:text-sm"
                    >® MARCAS REGISTRADAS; D.R. © 2024 UTILIZADOS
                    BAJO LICENCIA DE SU TITULAR GRUPO BIMBO, S.A.B DE
                    C.V</p>
                </div>
            </div>
            <div className="flex justify-between items-center mx-3 ">
                    <div>
                    <a href="https://www.bimbo.com.mx/es/terminos-y-condiciones" target="_blank" rel="noreferrer"
                        className="text-[#ffffffad] hover:text-[#ffffff] transition-all underline text-xs md:text-sm"
                    >Términos y condiciones</a>
                    </div>
                    <div>
                        <p
                            className="text-[#ffffffad] transition-all text-center text-xs md:text-sm"
                        >"Come bien"</p>
                    </div>
                    <div>
                        <a href="https://privacy.grupobimbo.com/mexico/policy/sitiosweb/index.html" target="_blank" rel="noreferrer"
                            className="text-[#ffffffad] hover:text-[#ffffff] transition-all underline text-xs md:text-sm"
                        >Aviso de privacidad</a>
                    </div>
            </div> 
        
        </footer>

    </>
  );
};

export default SelectionScreen;